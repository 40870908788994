import CloudPlatformLogo from "@/assets/images/PackNetCloud_Platform_LightBG.svg";
import LoadingBar from "@/components/loadingBar.jsx";
import { createUseStyles, useTheme } from "react-jss";
import { clearLocalStorage } from "@/api/local-storage.js";
import { Button } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { getNetworkSpeed } from "@/utils/network-detection.js";
import { FormattedMessage } from "react-intl";

const useStyles = createUseStyles({
	loaderContainer: {
		width: "100%",
		height: "100vh",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		gap: "24px",
	},
	loaderImage: {
		height: "60px",
		marginBottom: "3rem",
	},
	refreshContainer: {
		height: "96px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "32px",
	},
	refreshText: {
		fontFamily: "Stolzl-Book",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "400",
		lineHeight: "normal",
	},
});

const LoadingScreen = () => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [showSlow, setShowSlow] = useState(false);
	const [showRefresh, setShowRefresh] = useState(false);

	useEffect(() => {
		getNetworkSpeed().then((speed) => {
			if (speed <= 10) {
				setTimeout(() => setShowSlow(true), 5000);
				setTimeout(() => {
					setShowRefresh(true);
					setShowSlow(false);
				}, 10000);
			} else {
				setTimeout(() => setShowRefresh(true), 5000);
			}
		});
	}, []);
	const clearAndRefresh = () => {
		clearLocalStorage();
		window.location.reload();
	};

	return (
		<div className={classes.loaderContainer}>
			<div data-testid="loader">
				<img
					data-cy="loader"
					src={CloudPlatformLogo}
					style={{ height: "60px" }}
					className={classes.loaderImage}
					alt="PackNet"
				/>
				<LoadingBar />
			</div>

			<div className={classes.refreshContainer}>
				{showSlow && !showRefresh && (
					<div className={classes.refreshText}>
						<FormattedMessage id={"Slow network connection detected, please wait while we try to connect..."} />
					</div>
				)}

				{showRefresh && (
					<>
						<div className={classes.refreshText}>
							<FormattedMessage id={"This is taking longer than usual... try reloading PackNet."} />
						</div>

						<Button primary onClick={clearAndRefresh}>
							Reload PackNet
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default LoadingScreen;

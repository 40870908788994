export const readLocalStorage = (key) => {
	const item = window.localStorage.getItem(key);
	if (item === "null") return null;
	else return item;
};

export const writeLocalStorage = (key, data) => {
	window.localStorage.setItem(key, data);
};

export const removeLocalStorage = (key) => {
	window.localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
	window.localStorage.clear();
};

import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles({
	"@keyframes loadingAnimation": {
		"0%": {
			backgroundPosition: "100% 0",
		},
		"50%": {
			backgroundPosition: "0 0",
		},
		"100%": {
			backgroundPosition: "100% 0",
		},
	},

	loadingContainer: {
		borderRadius: "8px",
		background: "linear-gradient(to right, #E1E1C5 30%, #B9BE10 50%, #E1E1C5 70%)",
		backgroundSize: "200%",
		width: "100%",
		height: "8px",
		backgroundPosition: "100% 0",
		animationDuration: "3s",
		animationIterationCount: "infinite",
		animationTimingFunction: "cubic-bezier(0.45, 0, 0.55, 1)",
		animationName: "$loadingAnimation",
	},
});

const LoadingBar = () => {
	const theme = useTheme();
	const classes = useStyles({ theme });

	return <div className={classes.loadingContainer}></div>;
};

export default LoadingBar;

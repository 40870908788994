import moment from "moment-timezone";

const MINUTE_MILLISECONDS = 1000 * 60;
const HOURS_MILLISECONDS = 60 * MINUTE_MILLISECONDS;
const DAYS_MILLISECONDS = 24 * HOURS_MILLISECONDS;
export const LONG_TZ_DATE_FORMAT = "yyyy-MM-DDTHH:mm:ssZ";
export const LONG_DATE_FORMAT = "yyyy-MM-DDTHH:mm:ss";
export const FILE_LONG_DATE_FORMAT = "yyyy.MM.DDTHH_mm_ss";
export const SHORT_DATE_FORMAT = "MM/DD/yyyy";
export const FILE_SHORT_DATE_FORMAT = "MM.DD.yyyy";

export function validateMomentObject(date) {
	if (!moment.isMoment(date)) {
		throw new Error("Invalid date object. Expected a moment-timezone object.");
	}
}

export function isDateToday(date, timezone) {
	validateMomentObject(date);

	const today = moment.tz(timezone).startOf("day");
	const targetDate = date.clone().tz(timezone).startOf("day");

	return today.isSame(targetDate, "day");
}

export function isDateRangeSameDay(dateRange) {
	validateMomentObject(dateRange.start);
	validateMomentObject(dateRange.end);

	const adjustedEndFromMidnight = dateRange.end.clone().add(-1, "second");
	const areSameDay = dateRange.start.isSame(adjustedEndFromMidnight, "day");

	return dateRange.id === "Yesterday" || dateRange.id === "Today" || (dateRange.id === "Custom" && areSameDay);
}

export const getDateUnlessToday = (date, timezone) => {
	validateMomentObject(date);
	const now = moment.tz(timezone);
	const resultMoment = isDateToday(date, timezone) ? now : date;

	return resultMoment;
};

export function differenceMilliseconds(dateRange) {
	if (dateRange && dateRange.end) return dateRange.end.diff(dateRange.start);
}

export function differenceHours(dateRange) {
	return differenceMilliseconds(dateRange) / HOURS_MILLISECONDS;
}
export function differenceDays(dateRange) {
	return differenceMilliseconds(dateRange) / DAYS_MILLISECONDS;
}
export function moveEndDateToStartNextDayUnlessTodayOrPreset(dateRange, timezone) {
	if (dateRange.id !== "Custom") return dateRange;
	validateMomentObject(dateRange.end);

	const dateRangeResult = { ...dateRange };
	let newEndDate = dateRange.end.clone();

	if (isDateToday(newEndDate, timezone)) {
		dateRangeResult.end = moment.tz(timezone);
		return dateRangeResult;
	}

	newEndDate.add(1, "days");
	dateRangeResult.end = newEndDate.startOf("day");

	return dateRangeResult;
}

export function getTimeZoneOffset(timezone, momentDate = moment.tz(timezone)) {
	const offsetInMinutes = momentDate.utcOffset();
	return offsetInMinutes;
}

export function localTimezone() {
	return moment.tz.guess();
}

export function toMoment(date, timeZone) {
	return moment.tz(date, timeZone);
}

export function getTodayMomentStartOfDay(timeZone) {
	return moment.tz(timeZone).startOf("day");
}

export function parseDateToMomentStartOfDay(date, timeZone) {
	return toMoment(date, timeZone).startOf("day");
}

export function isDaySelected(dayMoment, tempSelected) {
	return tempSelected.some((selectedMoment) => selectedMoment.isSame(dayMoment, "day"));
}

export function format(momentDate, format = LONG_TZ_DATE_FORMAT) {
	return momentDate.format(format);
}

export function formatUri(momentDate) {
	return momentDate.format(LONG_DATE_FORMAT);
}

export function getDateIndex(dateRange) {
	let indexDate;
	switch (dateRange.id) {
		case "Today":
		case "Yesterday":
			indexDate = "Hours";
			break;
		case "Last7Days":
		case "Last30Days":
			indexDate = "Days";
			break;
		case "Custom": {
			const diff = differenceDays(dateRange);

			if (diff < 3) {
				indexDate = "Hours";
			} else if (diff < 31) {
				indexDate = "Days";
			} else if (diff < 90) {
				indexDate = "Weeks";
			} else {
				indexDate = "Months";
			}
			break;
		}
		default:
			break;
	}
	return indexDate;
}

export function convertToNewTimeZoneMaintainTime(date, originalTimeZone, newTimeZone) {
	const momentDate = moment.tz(date, originalTimeZone);
	return momentDate.clone().tz(newTimeZone, true);
}

import { getISOWeeksInYear, setISOWeek, startOfISOWeek } from "date-fns";

export function getStartOfWeekDate(w, y) {
	const max = getISOWeeksInYear(new Date(y, 0, 1));
	if (w < 1 || w > max) {
		return -1;
	} else {
		const d = setISOWeek(new Date(y, 1, 1), w);
		return startOfISOWeek(d, { weekStartsOn: 1 }).toLocaleDateString();
	}
}

export function twentyFourHourToTwelveHourHourFormat(rawHour) {
	let hour = rawHour % 12;
	if (hour === 0) hour = 12;
	let suffix = rawHour > 11 ? "pm" : "am";
	if (rawHour == 24) suffix = "am";
	return `${hour}${suffix}`;
}

export function timeLabelFormatter(dateObject, formatType, index, useRangeFormatForHours) {
	switch (formatType) {
		case "Hours":
			if (index % 4 === 0) {
				const d = new Date(dateObject.year, dateObject.month - 1, dateObject.day, dateObject.hour);
				const rawHour = d.getHours();
				if (useRangeFormatForHours)
					return `${twentyFourHourToTwelveHourHourFormat(rawHour)}
						- ${twentyFourHourToTwelveHourHourFormat(rawHour + 1)}`;
				return twentyFourHourToTwelveHourHourFormat(rawHour);
			}
			return "";
		case "Days":
			if (index % 3 === 0) {
				return new Date(dateObject.year, dateObject.month - 1, dateObject.day).toLocaleDateString();
			}
			return "";

		case "Weeks":
		case "Months":
			return new Date(dateObject.year, dateObject.month - 1, dateObject.day).toLocaleDateString();
		default:
			return "";
	}
}

//Please deprecate usage
export function isGuid(value) {
	if (!value) return false;

	const matches = value.match(
		//eslint-disable-next-line
		/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi,
	);
	if (!matches) return false;
	return matches.length > 0;
}

export function ensureSiteName(data, customerId) {
	for (const row of data) {
		if (!row.siteName || isGuid(row.siteName)) {
			row.siteName = customerId;
		}
	}
}

export function padZeroIfNeeded(value) {
	if (value < 10) {
		return `0${value}`;
	}
	return value;
}

import { Dropdown, Icon } from "semantic-ui-react";
import { ArrowCircleUp, HighlightOff, Upgrade } from "@styled-icons/material-rounded";
import Flex from "@/components/flex";
import TwoLineDropdownItem from "@/components/dropdown/two-line-dropdown-item";
import { useCanManageJobs } from "@/components/user-context";
import { useContext } from "react";
import { ProductionContext } from "@/contexts/production-context";
import { OrdersContext } from "../orders";

const InProgressJobsActionMenu = ({ batchId }) => {
	const { currentMachineGroup } = useContext(ProductionContext);
	const { scanToTriggerSingleConfigured, removeJob, triggerSingleJob, produceNow } = useContext(OrdersContext);
	const userCanManageJobs = useCanManageJobs();

	return (
		<Dropdown icon={<Icon name="ellipsis vertical" />} direction="left">
			<Dropdown.Menu>
				{scanToTriggerSingleConfigured && (
					<Dropdown.Item onClick={() => triggerSingleJob(currentMachineGroup.id, batchId)}>
						<Flex alignCenter gap={12}>
							<ArrowCircleUp size={24} />
							<TwoLineDropdownItem mainTextId="Trigger" subTextId="Produce one job in batch" />
						</Flex>
					</Dropdown.Item>
				)}
				<Dropdown.Item onClick={() => produceNow(batchId)}>
					<Flex alignCenter gap={12}>
						<Upgrade size={24} />
						<TwoLineDropdownItem mainTextId="Resume Production" subTextId="Produce now" />
					</Flex>
				</Dropdown.Item>
				{userCanManageJobs && (
					<Dropdown.Item onClick={() => removeJob(batchId)}>
						<Flex alignCenter gap={12}>
							<HighlightOff size={24} />
							<TwoLineDropdownItem mainTextId="Cancel Production" subTextId="Do not produce" />
						</Flex>
					</Dropdown.Item>
				)}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default InProgressJobsActionMenu;

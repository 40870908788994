import React, { useCallback, useContext, useMemo } from "react";
import { isFeatureFlagEnabled } from "@/utils/auth";
export const UserContext = React.createContext({
	currentUser: {
		id: "",
		roles: [],
		role: [],
		token: "",
	},
});

export const useLaunchDarkly = () => {
	const { currentUser } = useContext(UserContext);

	return useCallback((launchDarklyKey) => isFeatureFlagEnabled(currentUser, launchDarklyKey), [currentUser]);
};

export const useIsRoleEnabled = (role) => {
	const { currentUser } = useContext(UserContext);

	return useMemo(() => {
		if (currentUser?.role) return currentUser.role.includes(role.toLowerCase());
		return false;
	}, [currentUser?.role, role]);
};

export const useCanManageJobs = () => {
	const { currentUser } = useContext(UserContext);
	return useMemo(() => {
		if (currentUser?.role)
			return currentUser.role.includes("managepackagingsolutions") || currentUser.role.includes("managejobactions");
		return false;
	}, [currentUser?.role]);
};

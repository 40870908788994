import { Button, Dropdown, Form, Grid, Header, Message, Table } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import Em from "@/assets/images/machine-type/em.png";
import { Fragment } from "react";
import { readLocalStorage } from "@/api/local-storage";
import { sortObjectsAscending } from "@/utils/sort";
import useAxios from "@/api/useAxios";
import { useNavigate } from "react-router";
import emptyId from "@/constants/emptyId";
import machineTypes from "@/constants/machineTypes";
import { baseRoutes } from "@/api/base-routes";

const useStyles = createUseStyles((theme) => ({
	saveButtonSegment: {
		border: "unset !important",
		boxShadow: "unset !important",
		margin: "10px !important",
	},
	ready: {
		margin: "0px !important",
		padding: "100px !important",
		backgroundColor: theme.colors.lightBackground,
		height: "calc(100vh - 220px) !important",
	},
	grid: {
		marginTop: "24px !important",
		maxWidth: "100vw",
		overflowX: "none",
		"& .row": {
			paddingTop: "0px !important",
		},
	},
	machine: {
		backgroundColor: theme.colors.white,
		marginTop: "0px !important",
		borderTopRightRadius: "6px",
		borderBottomRightRadius: "6px",
		paddingLeft: "40px !important",
		maxHeight: "calc(100vh - 215px)",
		height: "calc(100vh - 215px)",
	},
	machineHeading: {
		marginTop: "40px !important",
	},
	machineImage: {
		width: "266px",
	},
	trackHeader: {
		backgroundColor: `${theme.colors.white} !important`,
	},
	tracks: {
		margin: "0px !important",
	},
	tracksTableDiv: {
		backgroundColor: `${theme.colors.white} !important`,
		borderRadius: "6px",
		maxHeight: "calc(100vh - 215px)",
		height: "calc(100vh - 215px)",
	},
	tracksTable: {
		border: "unset !important",
	},
	buttons: {
		backgroundColor: "unset !important",
		"& tbody tr": {
			borderBottomWidth: "0px",
		},
		"& tr td": {
			borderTopWidth: "0px !important",
			padding: "15px !important",
		},
	},
	saveCell: {
		padding: "6px",
	},
	main: {
		paddingLeft: "unset !important",
	},
}));

const apiUrl = `${baseRoutes.em}/api/v1/machines`;

function EmChangeCorrugate({ corrugates, selectedMachine, setSelectedMachine }) {
	const navigate = useNavigate();
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const EmMachinesApi = useAxios(apiUrl, token);
	const [corrugateOptions, setCorrugateOptions] = useState([]);
	const [stale, setStale] = useState(false);
	const [machineStatus, setMachinStatus] = useState();
	const [tracks, setTracks] = useState(JSON.parse(JSON.stringify(selectedMachine.tracks)));
	const [errors, setErrors] = useState([]);
	const allowChanges =
		machineStatus === "offline" ||
		machineStatus === "paused" ||
		machineStatus === "machinechangingcorrugate" ||
		machineStatus === "changingcorrugate";
	const [allowChangeMessage, setAllowChangeMessage] = useState("Machine is initializing, please wait");

	useEffect(() => {
		const machineStatusTemp = selectedMachine?.status?.toLowerCase();
		setMachinStatus(machineStatusTemp);
		switch (machineStatusTemp) {
			case "initializing":
				setAllowChangeMessage("Machine is initializing, please wait");
				break;
			case "service":
			case "machineservice":
				setAllowChangeMessage("Machine is in service mode, changing z-Fold is not possible at this time");
				break;
			case "error":
				setAllowChangeMessage("Machine is in error mode, reset the machine and try again");
				break;
			case "online":
				setAllowChangeMessage("Machine is online, press pause to change z-Fold");
				break;
			default:
				setAllowChangeMessage("Put the machine in a changeable state");
		}
	}, [selectedMachine]);

	useEffect(() => {
		if (!stale) {
			const handle = setTimeout(() => setStale(true), 2000);
			return () => clearTimeout(handle);
		}

		async function getMachineInfo(machineId, abortController) {
			try {
				const response = await fetch(`${apiUrl}/${machineId}`, {
					headers: { Authorization: token },
					signal: abortController.signal,
				});

				if (abortController.signal.aborted || !response.ok) {
					throw new Error("Request did not successfully complete.");
				}
				const updateMachineInfo = await response.json();
				updateMachineInfo.machineType = machineTypes.Em;
				setSelectedMachine(updateMachineInfo);
			} catch (error) {
				console.error(error);
			}

			setStale(false);
		}

		const abortController = new AbortController();
		getMachineInfo(selectedMachine.id, abortController);
		return () => abortController.abort();
	}, [stale]);

	useEffect(() => {
		const convertedOptions = corrugates.map((corrugate) => ({
			key: corrugate.id,
			value: corrugate.id,
			text: corrugate.alias,
		}));
		setCorrugateOptions(convertedOptions);
	}, [corrugates]);

	useEffect(() => {
		var errors = [];
		var trackInfo = [];
		for (var i = 0; i < tracks.length; i++) {
			if (
				tracks[i].loadedCorrugate !== null &&
				tracks[i].loadedCorrugate !== undefined &&
				tracks[i].loadedCorrugate.id !== "00000000-0000-0000-0000-000000000000"
			) {
				trackInfo.push({
					trackNumber: tracks[i].trackNumber,
					offset: parseFloat(tracks[i].trackOffset),
					corrugate: tracks[i].loadedCorrugate,
				});
				if (tracks[i].trackOffset < selectedMachine.physicalMachineSettings.longHead.minimumPosition) {
					errors.push(intl.formatMessage({ id: "Offset can't be lower than minimum position" }));
				}
				if (tracks[i].trackOffset > selectedMachine.physicalMachineSettings.longHead.maximumPosition) {
					errors.push(
						intl.formatMessage(
							{ id: "Offset can't be larger than maximum position on track TRACK" },
							{ track: tracks[i].trackNumber },
						),
					);
				}
			}
		}

		var rollersInUse = [];
		for (i = 0; i < trackInfo.length; i++) {
			var hasRoller = false;
			for (var o = 0; o < selectedMachine.physicalMachineSettings.feedRoller.rollerStartingPositions.length; o++) {
				var roller = selectedMachine.physicalMachineSettings.feedRoller.rollerStartingPositions[o];
				if (
					trackInfo[i].offset <= roller.position &&
					roller.position <= trackInfo[i].offset + trackInfo[i].corrugate.width
				) {
					for (var trackCheck = 0; trackCheck < rollersInUse.length; trackCheck++) {
						if (
							rollersInUse[trackCheck].trackNumber === trackInfo[i].trackNumber &&
							rollersInUse[trackCheck].track !== trackInfo[i]
						) {
							errors.push(
								intl.formatMessage(
									{ id: "Track FIRSTTRACK can't be loaded since that roller is used on track SECONDTRACK" },
									{ firstTrack: trackInfo[i].trackNumber, secondTrack: rollersInUse[trackCheck].track.trackNumber },
								),
							);
						}
					}
					rollersInUse.push({ id: roller, trackNumber: roller.trackNumber, track: trackInfo[i] });
					hasRoller = true;
				}
			}
			if (!hasRoller) {
				// Looks like we didn't check if a track had a roller in 4.3 so we skip this check for now as well.
				// errors.push(intl.formatMessage({id:"No roller on track TRACK"},{track:trackInfo[i].trackNumber}));
			}
		}

		for (var first = 0; first < trackInfo.length; first++) {
			for (var second = first + 1; second < trackInfo.length; second++) {
				if (
					trackInfo[first].offset <= trackInfo[second].offset + trackInfo[second].corrugate.width &&
					trackInfo[second].offset <= trackInfo[first].offset + trackInfo[first].corrugate.width
				) {
					errors.push(
						intl.formatMessage(
							{ id: "Track FIRSTTRACK overlaps track SECONDTRACK" },
							{ firstTrack: trackInfo[first].trackNumber, secondTrack: trackInfo[second].trackNumber },
						),
					);
				}
			}
		}

		setErrors(errors);
	}, [tracks]);

	const validateField = (field, value) => {
		const num = parseFloat(value);

		field.error = { pointing: "left" };
		if (isNaN(num)) {
			field.error.content = intl.formatMessage({ id: "Offset must be a number" });
			return;
		}
		if (num < selectedMachine.physicalMachineSettings.longHead.minimumPosition) {
			field.error.content = intl.formatMessage({ id: "Offset is outside the machine boundaries" });
			return;
		}
		if (num > selectedMachine.physicalMachineSettings.longHead.maximumPosition) {
			field.error.content = intl.formatMessage({ id: "Offset is outside the machine boundaries" });
			return;
		}
		delete field.error;
	};

	return (
		<Grid className={classes.grid}>
			<Grid.Row>
				<Grid.Column width={3} className={classes.machine}>
					<Grid>
						<Grid.Row>
							<Grid.Column>
								<Header as="h2" textAlign="left" className={classes.machineHeading}>
									<Header.Subheader>
										<FormattedMessage id="Machine" />
									</Header.Subheader>
									{selectedMachine.alias}
									<Header.Subheader>{selectedMachine.machineType}</Header.Subheader>
								</Header>
								<img src={Em} className={classes.machineImage} alt="EM" />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Grid.Column>
				<Grid.Column width={13} className={classes.main}>
					<Fragment>
						{tracks?.length === 0 ? (
							<div>Error: Incorrect Machine Configuration: No Tracks Configured</div>
						) : (
							<Grid className={classes.tracks}>
								<Grid.Row>
									<Grid.Column width={16}>
										{!allowChanges && (
											<Message warning>
												<FormattedMessage id={allowChangeMessage} />
											</Message>
										)}
										<div className={classes.tracksTableDiv}>
											<Table className={classes.tracksTable}>
												<Table.Header>
													<Table.Row>
														<Table.HeaderCell className={classes.trackHeader}>
															<FormattedMessage id="Track" />
														</Table.HeaderCell>
														<Table.HeaderCell className={classes.trackHeader}>
															<FormattedMessage id="Selected zFold" />
														</Table.HeaderCell>
														<Table.HeaderCell className={classes.trackHeader}>
															<FormattedMessage id="Track Offset" />
														</Table.HeaderCell>
														<Table.HeaderCell className={classes.trackHeader}>
															{allowChanges && (
																<Button
																	primary
																	disabled={errors.length > 0}
																	floated="right"
																	onClick={() => {
																		EmMachinesApi.updateWithUrl(
																			`${selectedMachine.id}/tracks`,
																			tracks,
																			() => {
																				navigate("/production/job-queue");
																			},
																			(err) => {
																				//Not Modified
																				if (err?.response?.status === 304) console.error("Track update failed", err);
																			},
																		);
																	}}
																>
																	<FormattedMessage id="Save" />
																</Button>
															)}
														</Table.HeaderCell>
													</Table.Row>
												</Table.Header>
												<Table.Body>
													{tracks.map((t) => (
														<Table.Row key={"Track" + t.trackNumber}>
															<Table.Cell>{t.trackNumber}</Table.Cell>
															<Table.Cell>
																<Dropdown
																	selection
																	disabled={!allowChanges}
																	value={t.loadedCorrugate?.id ?? emptyId}
																	options={corrugateOptions}
																	onChange={(_, { value }) => {
																		const newCorrugate = corrugates.find((c) => c.id === value);
																		const newTrack = { ...t, loadedCorrugate: { ...newCorrugate } };
																		setTracks(
																			sortObjectsAscending(
																				[...tracks.filter((tt) => tt.trackNumber !== t.trackNumber), newTrack],
																				"trackNumber",
																			),
																		);
																	}}
																	tabIndex={2}
																/>
															</Table.Cell>
															<Table.Cell>
																<Form.Input
																	value={t.trackOffset}
																	disabled={!allowChanges}
																	error={t.error}
																	onChange={(_, { value }) => {
																		const newTrack = { ...t, trackOffset: value };
																		setTracks(
																			sortObjectsAscending(
																				[...tracks.filter((tt) => tt.trackNumber !== t.trackNumber), newTrack],
																				"trackNumber",
																			),
																		);
																		validateField(newTrack, value);
																	}}
																/>
															</Table.Cell>
															<Table.Cell>
																<Button
																	basic
																	disabled={!allowChanges}
																	primary
																	onClick={async () => {
																		await EmMachinesApi.addWithUrl(
																			`${selectedMachine.id}/tracks/${t.trackNumber}/changecorrugate`,
																		);
																	}}
																>
																	<FormattedMessage id="Release Brakes" />
																</Button>
															</Table.Cell>
														</Table.Row>
													))}
												</Table.Body>
											</Table>
											<ul>
												{errors.map((err) => (
													<li key={"validationError" + err}>{err}</li>
												))}
											</ul>
										</div>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						)}
					</Fragment>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
}

export default EmChangeCorrugate;

const colors = {
	primary: "#0068ab !important", // primary brand
	primaryLight: "rgba(0, 104, 171, 0.25)",
	primaryDark: "#2e475f",
	secondary: "#b9be10", // secondary brand
	navigation: "#2e475f",
	text: "#353430",
	subtleText: "#7b7c79", // placeholder & helper text
	disabled: "#a2adb3", // placeholder & helper text
	accent: "#e3e7e9", // textfield borders, hairlines, bg option 2
	light: "#f2f4f5", // background
	dark: "#000000", // overlays - 50%, shadows
	success: "#7ed321",

	darkBackground: "#3e4e56",
	darkBlueBackground: "#2e475f",
	lightBackground: "#f2f4f5",
	border1: "#e3e7e9",
	border2: "#eff2f4",
	carton: "#f0d19a",
	darkText: "#3e4456",
	greyText: "#a2adb3",
	darkGreyText: "#7c7b79",
	lightText: "#ffffff",
	white: "#ffffff",
	online: "#80d424 !important",
	paused: "#FBBD08 !important",
	error: "#ff3743 !important",
	warning: "#ffc000 !important",

	/*--------------------------------------------------
		Colors should not be !important
	----------------------------------------------------*/
	errorRed: "#f6bbc0",
	warningYellow: "#f7e4ac",
	offlineGrey: "#b9baba",
	onlineGreen: "#80d424",
};

const fonts = {
	medium: "Stolzl-Medium",
	small: "Stolzl-Book",
	regular: "Stolzl",
};

const fontSizes = {
	48: "3rem",
	40: "2.5rem",
	32: "2rem",
	24: "1.5rem",
	16: "1rem",
	14: "0.875rem",
	12: "0.75rem",
};

const typography = {
	title: {
		"font-family": fonts.medium,
		"font-size": "3rem !important",
		"letter-spacing": "-0.5px",
	},
	header1: {
		"font-size": "2rem",
		"letter-spacing": "-0.5px",
	},
	header2: {
		"font-family": `${fonts.medium} !important`,
		"font-size": "1.5rem !important",
	},
	header3: {
		"font-family": fonts.medium,
		"font-size": "1rem",
	},
	subtitle: {
		"font-family": fonts.small,
		"font-size": "1.5rem",
	},
	body: {
		"font-family": fonts.small,
		"font-size": "1rem",
		"line-height": "140%",
	},
	button: {
		"font-family": fonts.medium,
		"font-size": "14px !important",
		"letter-spacing": "0.5px",
	},
	bodyBold: {
		"font-family": fonts.medium,
		"font-size": "1rem",
		"line-height": "140%",
	},
	textSmall: {
		"font-family": fonts.small,
		"font-size": "0.875rem",
		"line-height": "140%",
	},
	textMedium: {
		"font-family": fonts.medium,
		"font-size": "1.5rem",
	},
	textRegularGrey: {
		"font-family": fonts.regular,
		"font-size": "1rem",
		color: colors.darkGreyText,
	},
	textSmallBold: {
		"font-family": fonts.regular,
		"font-size": "0.875rem",
		"line-height": "140%",
	},
	textSmaller: {
		"font-family": fonts.small,
		"font-size": "0.75rem",
		"line-height": "140%",
	},
	linkText: {
		"font-family": fonts.regular,
		"font-size": "1rem",
	},
};

const iconButton = {
	padding: "unset !important",
	height: "40px !important",
	width: "40px !important",
};

const configurationDialog = {
	group: {
		padding: "0px !important",
		border: "0px !important",
		boxShadow: "none !important",
	},
	topGrid: {
		paddingBottom: "30px !important",
		maxHeight: "calc(100vh - 300px)",
		overflowX: "auto",
		marginRight: "-1rem !important",
	},
	header: {
		paddingTop: "24px !important",
		backgroundColor: `${colors.lightBackground} !important`,
		height: "112px !important",
		paddingRight: "0px !important",
		paddingLeft: "24px !important",
	},
	headerText: {
		paddingTop: "0px !important",
		fontSize: "40px !important",
	},
	card: {
		padding: "40px !important",
		paddingTop: "24px !important",
		paddingBottom: "24px !important",
	},
	cardFrame: {
		color: colors.text,
		backgroundColor: colors.white,
		borderRadius: "6px",
		padding: "24px",
	},
	form: {
		backgroundColor: `${colors.lightBackground} !important`,
		borderTop: "0px !important",
		paddingTop: "20px !important",
		paddingLeft: "24px !important",
		paddingRight: "24px !important",
		paddingBottom: "40px !important",
		"& .ui.checkbox label": {
			fontSize: "16px !important",
			fontWeight: "bolder !important",
		},
		"& .field label": {
			fontSize: "16px !important",
			fontWeight: "bolder !important",
		},
		"& .pointing": {
			marginTop: "0 !important",
		},
		"& .ui.form .field .prompt.label": {
			backgroundColor: "unset !important",
			border: "0px !important",
			fontSize: "14px",
		},
		"& .ui.pointing.label:before": {
			borderWidth: "0px",
		},
	},
	footer: {
		paddingTop: "0px !important",
		paddingBottom: "40px !important",
		borderTop: "0px !important",
		backgroundColor: `${colors.lightBackground} !important`,
		display: "flex",
		"justify-content": "center",
	},
	saveButton: {
		marginRight: "40px !important",
		"& .ui.disabled.button": {
			backgroundColor: `${colors.accent} !important`,
			color: `${colors.greyText} !important`,
			opacity: "1 !important",
		},
	},
	closeButton: {
		boxShadow: "none !important",
		margin: "0 !important",
		marginRight: "24px !important",
	},
	listView: {
		table: {
			margin: "0px !important",
			padding: "0px !important",
			"& thead tr": {
				height: "56px",
			},
			"& thead th": {
				paddingTop: "16px !important",
				paddingBottom: "16px !important",
				fontSize: "16px",
				height: "56px",
				fontWeight: "normal !important",
				fontStretch: "normal",
				fontStyle: "normal",
				lineHeight: "normal",
				letterSpacing: "normal",
				color: "#353430",
				borderBottom: "0 !important",
			},
			"& tbody tr": {
				borderBottom: "0px !important",
			},
			"& tbody tr td": {
				fontSize: "16px",
				fontWeight: "400",
				paddingTop: "3px !important",
				paddingBottom: "3px !important",
				borderTop: "2px solid #f2f4f5 !important",
				lineHeight: "19px",
				fontFamily: fonts.small,
			},
			"& thead th:first-child": {
				paddingLeft: "32px !important",
			},
			"& tbody tr td:first-child": {
				paddingLeft: "32px !important",
			},
			"& thead th:last-child": {
				paddingLeft: "0px !important",
				paddingRight: "32px !important",
			},
			"& tbody tr td:last-child": {
				paddingLeft: "0px !important",
				paddingRight: "32px !important",
			},
			"& thead th:nth-last-child(2)": {
				padding: "0px !important",
			},
			"& tbody tr td:nth-last-child(2)": {
				padding: "0px !important",
			},
		},
		loading: {
			height: "200px",
			display: "flex",
			alignItems: "center",
		},
		actionButtons: {
			"& > .ui.basic.button:first-child": {
				boxShadow: "none !important",
			},
		},
	},
	inputError: {
		position: "absolute",
		right: "11px",
		top: "12px",
	},
	nudContainer: {
		display: "flex",
		"align-items": "baseline",
	},
	nudText: {
		"padding-right": "30px",
		"margin-bottom": "1em",
	},
};

const optimalProductionTable = {
	outer: {
		padding: "0px",
		marginLeft: "16px !important",
		marginRight: "16px !important",
		marginBottom: "16px",
	},

	grid: {
		height: "100%",
		padding: "0px",
	},

	table: {
		borderLeft: "none !important",
		borderRight: "none !important",
		borderRadius: "0px !important",
		padding: "0px !important",
		marginTop: "16px !important",
	},

	header: {
		margin: "0 !important",
		padding: "0 !important",
		lineHeight: "24px !important",
		fontSize: "16px",
	},

	columnLabelText: {
		color: "#353430",
		fontWeight: "normal !important",
		margin: "32px !important",
		fontSize: "16px !important",
		fontFamily: fonts.medium,
		paddingLeft: "32px !important",
	},

	tableItemText: {
		color: "#353430 !important",
		fontSize: "16px !important",
		fontFamily: fonts.small,
		paddingLeft: "32px !important",
	},

	tableRow: {
		height: "70px !important",
		maxHeight: "70px !important",
	},

	actionItem: {
		borderWidth: "0px !important",
		padding: "0px !important",
	},
};

const controls = {
	select: {
		"& i": {
			lineHeight: ".5 !important",
			fontSize: "1.3em !important",
		},
	},
};

const mainPage = {
	leftPadding: {
		paddingLeft: "32px",
	},
	rightPadding: {
		paddingRight: "32px",
	},
	header: {
		...typography.header1,
		backgroundColor: colors.lightBackground,
		fontFamily: fonts.medium,
		paddingTop: "39.75px",
		paddingBottom: "39.75px",
		letterSpacing: "-0.5px",
	},
};

const measurements = {
	navBarHeight: "4rem", //64px
	// Height of the blue menu bar plus the white sub-menu bar.
	menuBarHeight: "128px",

	// Height of the page title (the grey area below the two
	// menu bars). Note that this does not include any components
	// that also live in the header below the page title.
	pageHeaderHeight: "96px",
};

const theme = {
	...colors,
	...typography,
	colors,
	fonts,
	fontSizes,
	typography,
	iconButton,
	configurationDialog,
	optimalProductionTable,
	controls,
	mainPage,
	measurements,
};

export default theme;

import { Form } from "semantic-ui-react";
import { Fragment, useEffect } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { FormattedMessage } from "react-intl";
import RadioButton from "@/components/radio-button";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	prompt: {
		paddingBottom: "16px",
	},
	radioGroup: {
		display: "flex",
		alignItems: "flex-start",
		"& > *": {
			marginRight: "32px",
		},
		paddingBottom: "8px",
	},
	radioBlock: {
		marginTop: "8px",
		paddingTop: "2px",
		display: "block !important",
		fontFamily: "Stolzl-Book !important",
	},
}));

const DimensioningBool = (props) => {
	const theme = useTheme();
	const classes = useStyles({ theme });

	useEffect(() => {
		if (props.product.canLayer == null) {
			props.setProduct({ ...props.product, canLayer: false });
		}
	}, []);

	return (
		<Fragment>
			<Form.Field className={classes.prompt}>{props.prompt ? props.prompt : props.defaultPrompt}</Form.Field>
			<div className={classes.radioGroup}>
				<RadioButton
					name="layering"
					className={classes.radioBlock}
					value="yes"
					checked={props.product?.canLayer ?? false}
					onChange={() => props.setProduct({ ...props.product, canLayer: true })}
				>
					<FormattedMessage id="Yes" />
				</RadioButton>
				<RadioButton
					name="layering"
					className={classes.radioBlock}
					value="no"
					checked={!props.product?.canLayer}
					onChange={() => props.setProduct({ ...props.product, canLayer: false })}
				>
					<FormattedMessage id="No" />
				</RadioButton>
			</div>
		</Fragment>
	);
};

export default DimensioningBool;

import { baseRoutes } from "@/api/base-routes";
import { constructUrl, fetchData, manageCancelToken } from "../../../functions/api-request";

export async function getMachinesInTimeRange(schedules, sites, dateFilter) {
	if ((!sites.length && !dateFilter?.id) || !dateFilter.start) {
		return [];
	}

	const endpoint = `${baseRoutes.reporting}/v1/MachineSelection`;
	const url = constructUrl(endpoint, dateFilter, false);
	const body = { schedules, sites };
	const cancelToken = manageCancelToken(endpoint);

	return fetchData(url, body, cancelToken);
}

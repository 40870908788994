import { Button, Header, Radio, Tab, Table } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import { baseRoutes } from "@/api/base-routes";

const useStyles = createUseStyles({
	radio: {
		margin: "16px !important",
		marginBottom: "-6px !important",
	},
});

const LongHeadControl = ({
	classes,
	longhead,
	creaseTools,
	cutTools,
	activeLatches,
	selectedMachine,
	setSelectedMachine,
	emServiceApi,
}) => {
	return (
		<Table.Row>
			<Table.Cell width={6}>
				<Header as="h4">
					<Header.Content>
						<FormattedMessage id="LongHead {longheadNumber}" values={{ longheadNumber: longhead.number }} />
					</Header.Content>
				</Header>
			</Table.Cell>
			<Table.Cell textAlign="center">
				<Header as="h5">
					<Header.Content>
						<FormattedMessage id="Crease Ring" />
						<Header.Subheader>
							<FormattedMessage id="Off" />
							<Radio
								className={classes.radio}
								toggle
								onChange={async () => {
									const current = creaseTools.includes(longhead.number);
									await emServiceApi.addWithUrl(
										current
											? `${selectedMachine.id}/longheads/${longhead.number}/raisecreasewheel`
											: `${selectedMachine.id}/longheads/${longhead.number}/activatecreasewheel`,
									);
									setSelectedMachine({
										...selectedMachine,
										creaseTools: current
											? creaseTools.filter((i) => i !== longhead.number)
											: [...creaseTools, longhead.number],
									});
								}}
								checked={creaseTools.includes(longhead.number)}
							/>
							<FormattedMessage id="On" />
						</Header.Subheader>
					</Header.Content>
				</Header>
			</Table.Cell>
			<Table.Cell textAlign="center">
				<Header as="h5">
					<Header.Content>
						<FormattedMessage id="Latch" />
						<Header.Subheader>
							<FormattedMessage id="Off" />
							<Radio
								className={classes.radio}
								toggle
								onChange={async () => {
									const current = activeLatches.includes(longhead.number);
									await emServiceApi.addWithUrl(
										current
											? `${selectedMachine.id}/longheads/${longhead.number}/deactivatelatch`
											: `${selectedMachine.id}/longheads/${longhead.number}/activatelatch`,
									);
									setSelectedMachine({
										...selectedMachine,
										activeLatches: current
											? activeLatches.filter((i) => i !== longhead.number)
											: [...activeLatches, longhead.number],
									});
								}}
								checked={activeLatches.includes(longhead.number)}
							/>
							<FormattedMessage id="On" />
						</Header.Subheader>
					</Header.Content>
				</Header>
			</Table.Cell>
			<Table.Cell textAlign="center">
				<Header as="h5">
					<Header.Content>
						<FormattedMessage id="Cut" />
						<Header.Subheader>
							<FormattedMessage id="Off" />
							<Radio
								className={classes.radio}
								toggle
								onChange={async () => {
									const current = cutTools.includes(longhead.number);
									await emServiceApi.addWithUrl(
										current
											? `${selectedMachine.id}/longheads/${longhead.number}/raiseknife`
											: `${selectedMachine.id}/longheads/${longhead.number}/activateknife`,
									);
									setSelectedMachine({
										...selectedMachine,
										cutTools: current ? cutTools.filter((i) => i !== longhead.number) : [...cutTools, longhead.number],
									});
								}}
								checked={cutTools.includes(longhead.number)}
							/>
							<FormattedMessage id="On" />
						</Header.Subheader>
					</Header.Content>
				</Header>
			</Table.Cell>
		</Table.Row>
	);
};

const TrackControl = ({
	classes,
	track,
	brakeTools,
	rollerTools,
	selectedMachine,
	setSelectedMachine,
	emServiceApi,
}) => {
	return (
		<Table.Row>
			<Table.Cell width={6}>
				<Header as="h4">
					<Header.Content>
						<FormattedMessage id="Track {trackNumber}" values={{ trackNumber: track.trackNumber }} />
					</Header.Content>
				</Header>
			</Table.Cell>
			<Table.Cell textAlign="center">
				<Header as="h5">
					<Header.Content>
						<FormattedMessage id="Brake" />
						<Header.Subheader>
							<FormattedMessage id="Off" />
							<Radio
								className={classes.radio}
								toggle
								onChange={async () => {
									const current = brakeTools.includes(track.trackNumber);
									await emServiceApi.addWithUrl(
										current
											? `${selectedMachine.id}/tracks/${track.trackNumber}/deactivatebrakes`
											: `${selectedMachine.id}/tracks/${track.trackNumber}/activatebrakes`,
									);
									setSelectedMachine({
										...selectedMachine,
										brakeTools: current
											? brakeTools.filter((i) => i !== track.trackNumber)
											: [...brakeTools, track.trackNumber],
									});
								}}
								checked={brakeTools.includes(track.trackNumber)}
							/>
							<FormattedMessage id="On" />
						</Header.Subheader>
					</Header.Content>
				</Header>
			</Table.Cell>
			<Table.Cell textAlign="center">
				<Header as="h5">
					<Header.Content>
						<FormattedMessage id="Pressure Roller" />
						<Header.Subheader>
							<FormattedMessage id="Off" />
							<Radio
								className={classes.radio}
								toggle
								onChange={async () => {
									const current = rollerTools.includes(track.trackNumber);
									await emServiceApi.addWithUrl(
										current
											? `${selectedMachine.id}/tracks/${track.trackNumber}/deactivatepressureroller`
											: `${selectedMachine.id}/tracks/${track.trackNumber}/activatepressureroller`,
									);
									setSelectedMachine({
										...selectedMachine,
										rollerTools: current
											? rollerTools.filter((i) => i !== track.trackNumber)
											: [...rollerTools, track.trackNumber],
									});
								}}
								checked={rollerTools.includes(track.trackNumber)}
							/>
							<FormattedMessage id="On" />
						</Header.Subheader>
					</Header.Content>
				</Header>
			</Table.Cell>
		</Table.Row>
	);
};

const CrossHeadControl = ({ classes, selectedMachine, setSelectedMachine, emServiceApi }) => (
	<Tab.Pane>
		<Table basic="very" celled>
			<Table.Body>
				<Table.Row>
					<Table.Cell width={6}>
						<Header as="h4">
							<Header.Content>
								<FormattedMessage id="Crosshead" />
							</Header.Content>
						</Header>
					</Table.Cell>
					<Table.Cell textAlign="center">
						<Header as="h5">
							<Header.Content>
								<FormattedMessage id="Crease Ring" />
								<Header.Subheader>
									<FormattedMessage id="Off" />
									<Radio
										className={classes.radio}
										toggle
										onChange={async () => {
											await emServiceApi.addWithUrl(
												selectedMachine.activeCrossHeadCrease
													? `${selectedMachine.id}/raisecrossheadcreasewheel`
													: `${selectedMachine.id}/activatecrossheadcreasewheel`,
											);
											setSelectedMachine({
												...selectedMachine,
												activeCrossHeadCrease: !selectedMachine.activeCrossHeadCrease,
											});
										}}
										checked={selectedMachine.activeCrossHeadCrease}
									/>
									<FormattedMessage id="On" />
								</Header.Subheader>
							</Header.Content>
						</Header>
					</Table.Cell>
					<Table.Cell textAlign="center">
						<Header as="h5">
							<Header.Content>
								<FormattedMessage id="Latch" />
								<Header.Subheader>
									<FormattedMessage id="Off" />
									<Radio
										className={classes.radio}
										toggle
										onChange={async () => {
											await emServiceApi.addWithUrl(
												selectedMachine.activeCrossHeadLatch
													? `${selectedMachine.id}/deactivatecrossheadlatch`
													: `${selectedMachine.id}/activatecrossheadlatch`,
											);
											setSelectedMachine({
												...selectedMachine,
												activeCrossHeadLatch: !selectedMachine.activeCrossHeadLatch,
											});
										}}
										checked={selectedMachine.activeCrossHeadLatch}
									/>
									<FormattedMessage id="On" />
								</Header.Subheader>
							</Header.Content>
						</Header>
					</Table.Cell>
					<Table.Cell textAlign="center">
						<Header as="h5">
							<Header.Content>
								<FormattedMessage id="Cut" />
								<Header.Subheader>
									<FormattedMessage id="Off" />
									<Radio
										className={classes.radio}
										toggle
										onChange={async () => {
											await emServiceApi.addWithUrl(
												selectedMachine.activeCrossHeadCut
													? `${selectedMachine.id}/raisecrossheadknife`
													: `${selectedMachine.id}/activatecrossheadknife`,
											);
											setSelectedMachine({
												...selectedMachine,
												activeCrossHeadCut: !selectedMachine.activeCrossHeadCut,
											});
										}}
										checked={selectedMachine.activeCrossHeadCut}
									/>
									<FormattedMessage id="On" />
								</Header.Subheader>
							</Header.Content>
						</Header>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table>
	</Tab.Pane>
);

const CycleControl = ({ emServiceApi, selectedMachine }) => (
	<Tab.Pane>
		<Table basic="very" celled>
			<Table.Body>
				<Table.Row>
					<Table.Cell width={6}>
						<Header as="h4">
							<Header.Content></Header.Content>
						</Header>
					</Table.Cell>
					<Table.Cell textAlign="center">
						<Button primary onClick={() => emServiceApi.addWithUrl(`${selectedMachine.id}/cycleallcrossheadtools`)}>
							<FormattedMessage id="Cycle CrossHead Tools" />
						</Button>
					</Table.Cell>
					<Table.Cell textAlign="center">
						<Button primary onClick={() => emServiceApi.addWithUrl(`${selectedMachine.id}/cyclealllongheadtools`)}>
							<FormattedMessage id="Cycle LongHead Tools" />
						</Button>
					</Table.Cell>
					<Table.Cell textAlign="center">
						<Button primary onClick={() => emServiceApi.addWithUrl(`${selectedMachine.id}/cyclealltracktools`)}>
							<FormattedMessage id="Cycle Tracks" />
						</Button>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table>
	</Tab.Pane>
);

function EmService(props) {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const [selectedMachine, setSelectedMachine] = useState(props.selectedMachine);
	const EmServiceApi = useAxios(`${baseRoutes.em}/api/v1/machines`, token);

	const panes = [
		{
			menuItem: intl.formatMessage({ id: "LongHeads" }),
			render: () => (
				<Tab.Pane>
					<Table basic="very" celled>
						<Table.Body>
							{selectedMachine.physicalMachineSettings.longHead.longHeads.map((lh) => (
								<LongHeadControl
									classes={classes}
									key={lh.number}
									longhead={lh}
									creaseTools={selectedMachine.creaseTools ?? []}
									cutTools={selectedMachine.cutTools ?? []}
									activeLatches={selectedMachine.activeLatches ?? []}
									selectedMachine={selectedMachine}
									setSelectedMachine={setSelectedMachine}
									emServiceApi={EmServiceApi}
								/>
							))}
						</Table.Body>
					</Table>
				</Tab.Pane>
			),
		},
		{
			menuItem: intl.formatMessage({ id: "CrossHead" }),
			render: () => (
				<CrossHeadControl
					classes={classes}
					emServiceApi={EmServiceApi}
					selectedMachine={selectedMachine}
					setSelectedMachine={setSelectedMachine}
				/>
			),
		},
		{
			menuItem: intl.formatMessage({ id: "Tracks" }),
			render: () => (
				<Tab.Pane>
					<Table basic="very" celled>
						<Table.Body>
							{selectedMachine.tracks.map((t) => (
								<TrackControl
									classes={classes}
									key={t.trackNumber}
									track={t}
									brakeTools={selectedMachine.brakeTools ?? []}
									rollerTools={selectedMachine.rollerTools ?? []}
									selectedMachine={selectedMachine}
									setSelectedMachine={setSelectedMachine}
									emServiceApi={EmServiceApi}
								/>
							))}
						</Table.Body>
					</Table>
				</Tab.Pane>
			),
		},
		{
			menuItem: intl.formatMessage({ id: "Cycle Tools" }),
			render: () => <CycleControl emServiceApi={EmServiceApi} selectedMachine={selectedMachine} />,
		},
	];

	return <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={panes} />;
}

export default EmService;

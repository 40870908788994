import { format, isValid } from "date-fns";

export function sanitizeCsvOutput(value) {
	value = value.toString().trim();
	value = value.replaceAll('"', '""');
	value = value.replaceAll(`\t`, " ");
	value = value.replaceAll(`\r\n`, " ");
	value = value.replaceAll(`\n`, " ");

	const dangerousChars = ["=", "+", "-", "@", "\t", "\r", "\n"];
	if (dangerousChars.includes(value.charAt(0))) {
		return `'${value}`;
	}

	return `"${value}"`;
}

export function formatDateTime(value) {
	const date = new Date(value.split(".")[0]);
	if (isValid(date)) {
		return format(date, "yyyy-MM-dd HH:mm:ss");
	} else return "Invalid Date";
}

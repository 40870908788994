import { Button, Card, Checkbox, Form } from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { FormattedMessage, useIntl } from "react-intl";
import ApimModal from "./apim-modal";
import { UserContext } from "../../../../components/user-context";
import { ReactComponent as Copy } from "@/assets/images/copy.svg";
import ListContext from "../../../../components/list-context";
import { enqueueSnackbar } from "notistack";
import { identityApi } from "@/api/identitySdk.js";
import { useFeatureFlags } from "@/contexts/feature-flag-context.jsx";

const useStyles = createUseStyles((theme) => ({
	errorState: {
		color: "#FF3743 !important",
	},
	field: {
		"& .pointing.above.prompt.label": {
			border: "none !important",
			"margin-top": 0,
		},
		"& .field": {
			marginTop: ".5rem !important",
		},
		"& .label": {
			"&:before": {
				display: "none",
			},
		},
	},
	checkboxContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "2rem",
	},
	checkboxList: {
		gap: "1rem",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		"& div": {
			flexBasis: "40%",
			flex: "1",
		},
	},
	apiResponseBox: {
		"& .card": {
			backgroundColor: "#FFFFFF",
			padding: "1.5rem",
			width: "100%",
		},
		paddingTop: "20px",
	},
	copyButton: {
		display: "flex",
		flexDirection: "row !important",
		justifyContent: "space-between !important",
		flexBasis: "100%",
		alignContent: "center",
		"& div": {
			flexBasis: "100%",
			flex: 1,
			"&:last-child": {
				justifyContent: "end",
				display: "flex",
				"& path": {
					fill: theme.colors.primary,
				},
			},
		},
	},
	boldText: {
		fontFamily: "Stolzl",
	},
}));

const AddApiKey = () => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const intl = useIntl();
	const [hasFormErrors, setHasFormErrors] = useState(false);
	const [apiKeyName, setApiKeyName] = useState("");
	const { currentUser } = useContext(UserContext);
	const [modalStage, setModalStage] = useState("create");
	const [apiKeyInfo, setApiKeyInfo] = useState({});
	const { list, setList } = useContext(ListContext);
	const { featureFlagEnabled } = useFeatureFlags();
	let result;

	const copyToClipboard = async (value) => {
		enqueueSnackbar(intl.formatMessage({ id: "copiedToClipboard", defaultMessage: "Copied to clipboard" }), {
			variant: "customSuccess",
		});
		await navigator.clipboard.writeText(value);
	};

	const initialState = [
		{
			name: "migration",
			displayName: "Migration",
			value: false,
			mappedValue: "migration-v1",
			licenses: ["enterprise", "professional", "unlicensed"],
			optionalLicenses: [],
		},
		{
			name: "production",
			displayName: "Production",
			value: false,
			mappedValue: "production-api-v1",
			licenses: ["enterprise", "professional", "unlicensed"],
			optionalLicenses: [],
		},
		{
			name: "import",
			displayName: "Import",
			value: false,
			mappedValue: "importapi",
			licenses: ["enterprise", "professional", "unlicensed"],
			optionalLicenses: [],
		},
		{
			name: "cube",
			displayName: "Cube",
			value: false,
			mappedValue: "cubeapi",
			licenses: ["enterprise", "professional"],
			optionalLicenses: ["cube"],
		},
		{
			name: "dim",
			displayName: "Dim",
			value: false,
			mappedValue: "dimapi",
			licenses: ["enterprise", "professional"],
			optionalLicenses: ["dim"],
		},
	];
	const [checkboxes, setCheckboxes] = useState(initialState);

	useEffect(() => {
		setHasFormErrors(false);
	}, []);

	const checkFormForErrors = () => {
		setHasFormErrors(false);
		if (apiKeyName === "") {
			setHasFormErrors("name");
			return false;
		}
		if (checkboxes.filter((x) => x.value === true).length < 1) {
			setHasFormErrors("checkboxes");
			return false;
		}
		return true;
	};

	const handleSubmit = async () => {
		if (checkFormForErrors()) {
			let products = checkboxes.filter((x) => x.value).map((x) => x.mappedValue);
			try {
				result = await identityApi.createApimSubscription({
					DisplayName: apiKeyName,
					TenantId: currentUser.tid,
					SelectedProducts: products,
				});
			} catch {
				setHasFormErrors("submissionFailure");
			}
			setApiKeyInfo(result);
			setModalStage("display");
			setApiKeyName("");
			setCheckboxes(initialState);
			// need to handle non exceptional errors
		}
	};

	function handleChange(e, data) {
		const { name, checked } = data;
		const updatedCheckboxes = checkboxes.map((checkbox) =>
			checkbox.name === name ? { ...checkbox, value: checked } : checkbox,
		);
		setCheckboxes(updatedCheckboxes);
	}

	const modalTitle =
		modalStage === "create" ? (
			<FormattedMessage id="Generate API Key"></FormattedMessage>
		) : (
			<FormattedMessage id="Your API Key"></FormattedMessage>
		);

	const modalSubmit =
		modalStage === "create" ? (
			<Button id="generateButton" primary>
				<FormattedMessage id="Generate" />
			</Button>
		) : null;
	const modalClose = () => {
		setModalStage("create");
		setApiKeyName("");
		setCheckboxes(initialState);
		let stateUpdatedObject = [
			{ name: apiKeyInfo.responseData.name, properties: apiKeyInfo.responseData.properties },
			...list,
		];
		setList(stateUpdatedObject);
	};

	return (
		<ApimModal
			header={modalTitle}
			trigger={
				<Button id="generateApiKey" primary>
					<span>
						<FormattedMessage id="Generate API Key" />
					</span>
				</Button>
			}
			closeOnSubmit={false}
			submitFunction={handleSubmit}
			submit={modalSubmit}
			close={modalClose}
		>
			{modalStage === "create" ? (
				<Form className={classes.content}>
					<Form.Field className={classes.field}>
						<span
							className={
								hasFormErrors === "name" || hasFormErrors === "submissionFailure"
									? classes.errorState
									: classes.nameField
							}
						>
							{hasFormErrors === "submissionFailure" ? (
								<FormattedMessage id="Form Submission Failure, Please Try Again" />
							) : (
								<span>
									<FormattedMessage id="Name" />*
								</span>
							)}
						</span>
						<Form.Input
							fluid
							id="apiNameInput"
							type="text"
							error={hasFormErrors === "name"}
							tabIndex={1}
							value={apiKeyName}
							onChange={(e) => setApiKeyName(e.target.value)}
							maxLength={64}
						/>
					</Form.Field>
					<div className={hasFormErrors === "checkboxes" ? classes.errorState : ""}>
						<div className={classes.checkboxContainer}>
							<div>
								<FormattedMessage id="Select which services this key will be used for" />
							</div>
							<div className={classes.checkboxList}>
								{checkboxes &&
									checkboxes.map((component, index) => {
										let checkbox = (
											<div key={index}>
												<Checkbox
													id={component.name}
													checked={checkboxes.filter((x) => x.name === component.name).value}
													label={component.displayName}
													name={component.name}
													onChange={(e, data) => handleChange(e, data)}
												></Checkbox>
											</div>
										);

										if (featureFlagEnabled("vaporware-apim-license-display", false)) {
											if (
												component.licenses.includes(currentUser.licenseInfo.coreLicense.toLower()) ||
												currentUser.licenseInfo.optionalLicenses.filter((value) =>
													component.optionalLicenses.includes(value.toLower()),
												).length > 0
											)
												return checkbox;
										} else return checkbox;
									})}
							</div>
						</div>
					</div>
				</Form>
			) : (
				<div>
					<div>
						<div style={{ paddingBottom: "10px" }}>
							<FormattedMessage id="Here is your new API key" />
						</div>
						<div className={classes.boldText}>
							<FormattedMessage id="This is the only time this will be displayed, so be sure to copy it." />
						</div>
					</div>

					<div className={classes.apiResponseBox}>
						<span>
							<FormattedMessage id="Primary Key" />
						</span>
						<Card className={classes.copyButton}>
							<div>{apiKeyInfo.responseData?.properties?.primaryKey}</div>
							<div>
								<Copy primary onClick={() => copyToClipboard(apiKeyInfo.responseData?.properties?.primaryKey)} />
							</div>
						</Card>
					</div>

					<div className={classes.apiResponseBox}>
						<span>
							<FormattedMessage id="Secondary Key" />
						</span>
						<Card className={classes.copyButton}>
							<div>{apiKeyInfo.responseData?.properties?.secondaryKey}</div>
							<div>
								<Copy primary onClick={() => copyToClipboard(apiKeyInfo.responseData?.properties?.secondaryKey)} />
							</div>
						</Card>
					</div>
				</div>
			)}
		</ApimModal>
	);
};

export default AddApiKey;

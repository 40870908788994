/* eslint react/no-unknown-property: 0 */

import { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Edges } from "@react-three/drei";

function Box({ dim1, dim2, dim3 }) {
	const ref = useRef();
	const scaleFactor = 1 / Math.max(dim1, dim2, dim3);

	useFrame((state, delta) => (ref.current.rotation.y += delta));

	return (
		<mesh ref={ref} scale={[scaleFactor, scaleFactor, scaleFactor]}>
			<boxGeometry args={[dim1, dim2, dim3]} />
			<Edges
				linewidth={8}
				scale={1}
				threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
				color="tan"
			/>
			<Edges
				linewidth={1}
				scale={1}
				threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
				color="Gray"
			/>
			<meshStandardMaterial color={"lightGray"} />
		</mesh>
	);
}

export default function App({ dim1, dim2, dim3 }) {
	return (
		<Canvas style={{ width: 250, height: 250 }} camera={{ position: [5, 2, 1], fov: 25 }}>
			<ambientLight intensity={1} />
			<pointLight position={[10, 10, 5]} />
			<pointLight position={[-10, -10, -10]} />
			<Box dim1={dim1} dim2={dim3} dim3={dim2} />
		</Canvas>
	);
}

import { readLocalStorage } from "@/api/local-storage";
import machineTypes from "@/constants/machineTypes";
import errorSeverity from "@/constants/errorSeverity";
import { useEffect, useRef } from "react";
import { HttpTransportType } from "@microsoft/signalr";
import { createSignalRContext } from "react-signalr";
import { baseRoutes } from "@/api/base-routes";

const machineType = machineTypes.X4;
const SignalRContext = createSignalRContext();
const websocketUrl = `${baseRoutes.x4}/hubs/x4machines`;
const errorsChangedEventName = "OnErrorsUpdated";
const warningsChangedEventName = "OnWarningsUpdated";
const subscribeWarningsMethods = "SubscribeWarnings";
const subscribeErrorsMethods = "SubscribeErrors";
const unsubscribeErrorsMethod = "UnsubscribeErrors";
const unsubscribeWarningsMethod = "UnsubscribeWarnings";

function formatAlarmData(machineId, data, severity) {
	return (
		data?.map((d) => ({
			machineId,
			machineType,
			severity: severity,
			code: d.alarm ?? d.alarmNumber ?? d.code,
		})) ?? []
	);
}

export default function X4ErrorsAndWarnings({ machines, onErrorsChanged, onWarningsChanged }) {
	const token = readLocalStorage("BEARER");
	const machineIds = useRef([]);

	useEffect(() => {
		console.info(`Machines list updated. Current count: ${machines.length}`);
		unsubscribeMachineGroup();
		machineIds.current = machines
			.filter((x) => x.machineType.toLowerCase() === machineType.toLowerCase())
			.map((x) => x.machineId);
		subscribeMachineGroup();
	}, [machines]);

	function subscribeMachineGroup() {
		machineIds.current.forEach((id) => {
			SignalRContext.invoke(subscribeErrorsMethods, id);
			SignalRContext.invoke(subscribeWarningsMethods, id);
		});
	}

	function unsubscribeMachineGroup() {
		machineIds.current.forEach((id) => {
			SignalRContext.invoke(unsubscribeErrorsMethod, id);
			SignalRContext.invoke(unsubscribeWarningsMethod, id);
		});
	}

	SignalRContext.useSignalREffect(errorsChangedEventName, (_, machineId, newErrors) => {
		if (machineIds.current.includes(machineId)) {
			console.info(`Processing ${errorsChangedEventName} SignalR event for machine: ${machineId}`);
			onErrorsChanged(machineId, formatAlarmData(machineId, newErrors, errorSeverity.error));
		} else {
			console.info(`Got an error over SignalR for machine ${machineId}, but that is not in the active Machine Group!`);
		}
	});

	SignalRContext.useSignalREffect(warningsChangedEventName, (_, machineId, newWarnings) => {
		if (machineIds.current.includes(machineId)) {
			console.info(`Processing ${warningsChangedEventName} SignalR event for machine: ${machineId}`);
			onWarningsChanged(machineId, formatAlarmData(machineId, newWarnings, errorSeverity.warning));
		} else {
			console.info(`Got a warning over SignalR for machine ${machineId}, but that is not in the active Machine Group!`);
		}
	});

	return (
		<SignalRContext.Provider
			connectEnabled={!!token}
			accessTokenFactory={() => token.replace("BEARER ", "")}
			dependencies={[token]}
			transport={HttpTransportType.WebSockets}
			url={websocketUrl}
			onOpen={() => {
				console.info(`${machineType} websocket opened and is in the ${SignalRContext.connection?.state} state`);
				subscribeMachineGroup();
			}}
			onReconnect={() => {
				console.info(`${machineType} websocket reconnected and is in the ${SignalRContext.connection?.state} state`);
			}}
			onError={() => {
				console.info(`${machineType} websocket errored`);
			}}
			onClosed={() => {
				console.info(`${machineType} websocket closed`);
			}}
		/>
	);
}

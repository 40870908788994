import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { readLocalStorage, removeLocalStorage, writeLocalStorage } from "@/api/local-storage.js";
import { decode } from "@/api/jwtFunctions.js";
import { identityApi } from "@/api/identitySdk.js";
import { useNavigate } from "react-router";
import { getApplicationInsights } from "@/contexts/app-insights.jsx";
import { FullStory, isInitialized } from "@fullstory/browser";

const AuthenticationContext = createContext({});

export const setFullStoryIdentity = async (decodedToken) => {
	if (isInitialized()) {
		try {
			await FullStory("setIdentityAsync", {
				uid: decodedToken.id,
				properties: {
					userGuid: decodedToken.id,
					tenantId: decodedToken.Tenant,
					customerId: decodedToken.CustomerId,
					siteId: decodedToken.SiteId,
				},
			});
		} catch (e) {
			console.error("Error setting FullStory identity", e);
		}
	}
};

export const setAppInsightsIdentity = (decodedToken) => {
	let applicationInsights = getApplicationInsights(); // may be null before it has been initialized
	applicationInsights?.setAuthenticatedUserContext(decodedToken.id, decodedToken.tid, true);
};

export const deleteAppInsightsIdentity = () => {
	let applicationInsights = getApplicationInsights(); // may be null before it has been initialized
	applicationInsights?.clearAuthenticatedUserContext();
};

export const AuthenticationProvider = ({ children }) => {
	const initialized = useRef(false);
	const [token, _setToken] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (initialized.current) return;

		const localToken = readLocalStorage("BEARER");

		if (localToken == null || !localToken || !localToken.includes("BEARER")) {
			_setToken(undefined);
		} else {
			let clearedToken = localToken.replace("BEARER ", "");
			const decodedToken = decode(clearedToken);
			initAuthData(decodedToken, localToken);
		}

		initialized.current = true;
	}, []);

	const onStorage = useCallback(
		(e) => {
			if (e.key === "BEARER" && e.oldValue !== e.newValue) {
				console.info("Change to BEARER token stored in local storage from another browser window, refreshing page");
				navigate(0);
			}
		},
		[navigate],
	);

	useEffect(() => {
		window.addEventListener("storage", onStorage);

		return () => {
			window.removeEventListener("storage", onStorage);
		};
	}, [onStorage]);

	const setToken = (newToken) => {
		if (!newToken) {
			console.error("Could not find token");
			return;
		}

		const decodedToken = decode(newToken);
		const bearerToken = `BEARER ${newToken}`;

		initAuthData(decodedToken, bearerToken);

		removeLocalStorage("currentMachineGroup");
		writeLocalStorage("BEARER", bearerToken);
	};

	const initAuthData = (decodedToken, clearedToken) => {
		decodedToken.token = clearedToken;
		setAppInsightsIdentity(decodedToken);
		setFullStoryIdentity(decodedToken);
		identityApi.setSdkAuthenticationToken(decodedToken.token);
		_setToken(decodedToken);
	};

	const clearToken = () => {
		deleteAppInsightsIdentity();
		removeLocalStorage("BEARER");
		removeLocalStorage("currentMachineGroup");
		removeLocalStorage("dimension-products-search-term");
		removeLocalStorage("preAuth");
		identityApi.setSdkAuthenticationToken("");

		_setToken(null);
	};

	const refreshToken = async () => {
		const response = await identityApi.refreshToken();

		if (response.success) {
			setToken(response.data.access_token);
		}
	};

	return (
		<AuthenticationContext.Provider value={{ token, setToken, clearToken, refreshToken }}>
			{children}
		</AuthenticationContext.Provider>
	);
};

export const useAuthentication = () => useContext(AuthenticationContext);

import { Button, Grid } from "semantic-ui-react";
import { Fragment, useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { readLocalStorage } from "@/api/local-storage";
import { sortObjectsAscending } from "@/utils/sort";
import useAxios from "@/api/useAxios";
import { useIntl } from "react-intl";
import X4MachineCard from "./x4-machine-card";
import { UserContext } from "@/components/user-context";
import TracksCard from "./x4-tracks-card";
import ChangeCorrugateStatus from "./x4-corrugate-change-status-card";
import { baseRoutes } from "@/api/base-routes";

const useStyles = createUseStyles((theme) => ({
	...theme.typography,
	x4ChangeCorrugateId: { backgroundColor: "Green" },
	semanticGrid: {
		marginLeft: "0px !important",
		marginTop: "0px !important",
		height: "100vh",
	},
	semanticGridRow: {
		paddingTop: "0px !important",
		paddingBottom: "0px !important",
	},
	semanticGridCol: {},
	tablePadding: {
		paddingLeft: "2rem !important",
	},
	header: {
		...theme.typography.subtitle,
		color: theme.colors.text,
	},
	subHeader: {
		fontSize: theme.typography.body["font-size"] + "!important",
		color: theme.colors.text,
	},
	statusHeader: {
		...theme.typography.header2,
		color: "#353430",
		margin: "1rem 0 0 0",
	},
	statusText: {
		"font-family": "Stolzl",
		"font-size": "1.063rem",
		color: "#7c7b79",
		margin: "1rem 0 0 0",
	},
	machineHeading: {
		marginBottom: "2rem !important",
	},
	machineAlias: {
		marginTop: "0.5rem !important",
		height: "29px",
	},
	machineStatus: {
		marginTop: "0.25rem !important",
	},
	machineStatusText: {
		...theme.textSmallBold,
		marginLeft: ".25rem",
		paddingTop: ".125rem",
	},
	machineBox: {
		borderRadius: "0.375rem",
		backgroundColor: theme.colors.white,
		paddingTop: "1.5rem",
		paddingBottom: "1.5rem",
		height: "100%",
		paddingLeft: "2rem !important",
	},
	tracksBox: {
		borderRadius: "0.375rem",
		backgroundColor: theme.colors.white,
		height: "100%",
		"& th": {
			paddingTop: "1.5rem !important",
			paddingBottom: "1.5rem !important",
		},
		"& td": {
			paddingTop: "1.5rem !important",
			paddingBottom: "1.5rem !important",
		},
		"& td:last-child, th:last-child": {
			paddingRight: "2.5rem !important",
		},
	},
	trackTable: {
		boarderColor: "#e3e7e9",
		borderRadius: "0rem !important",
		borderTop: "none !important",
		borderRight: "none !important",
		borderLeft: "none !important",
	},
	dropDownBox: {
		"& div": {
			paddingTop: "0.15rem",
		},
		"& i": {
			position: "absolute !important",
			right: "1rem !important",
		},
		"& i.dropdown": {
			paddingTop: "1rem !important",
		},
		"& i.dont": {
			paddingTop: "0.25rem !important",
		},
		width: "100%",
		height: "3rem",
	},
	columns: {
		...theme.typography.textSmallBold,
		color: theme.colors.text,
	},
	pausePrompt: {
		textAlign: "center",
		marginTop: "7rem !important",
	},
	pauseButton: {
		...theme.typography.button,
		textTransform: "uppercase !important",
		marginTop: "1rem !important",
		"& span": {
			fontSize: ".75rem !important",
		},
	},
	saveButton: {
		right: "2rem",
		position: "absolute",
		top: "10rem",
		textTransform: "uppercase !important",
	},
	centerVertically: {
		display: "flex",
		alignItems: "center",
	},
	trackNumber: {
		fontSize: "16px !important",
	},
	info: {
		color: theme.colors.primary,
		boxShadow: `0em 0em 0em 0.2em inset !important`,
	},
	popup: {
		maxWidth: "500px",
	},
	center: {
		justifyContent: "center",
	},
	errorBox: {
		margin: "1em !important",
	},
}));

const X4ChangeCorrugate = ({ corrugates, selectedMachine }) => {
	const intl = useIntl();
	const refreshInterval = 2000;
	const [allowChange, setAllowChange] = useState(false);
	const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
	const [originalTracks, setOriginalTracks] = useState([]);
	const [succesfulSave, setSuccesfulSave] = useState(false);
	const [tracks, setTracks] = useState(selectedMachine.tracks);
	const [trackErrors, setTrackErrors] = useState({
		casset1: false,
		casset2: false,
		duplicate: false,
		corrugateTrackErrors: [],
	});
	const token = readLocalStorage("BEARER");
	const x4Api = useAxios(`${baseRoutes.x4}/api/v1.0`, token);
	const theme = useTheme();
	const { currentUser } = useContext(UserContext);

	const classes = useStyles({ theme });

	const convertToMM = (value) => {
		//Assumption value, corrugate width, can switch between inches and mm
		return currentUser.unitofmeasure === "inches" ? Math.round((value * 25.4 + Number.EPSILON) * 100) / 100 : value;
	};

	const getCorrugateWithinTolerance = (corrugate) =>
		corrugate.width !== 0 &&
		convertToMM(corrugate.width) <= selectedMachine.physicalMachineSettings.gantryParameters.maximumCorrugateWidth;

	const getValidCorrugatesForTrack = (machine, track) => {
		var res = [];
		[
			...corrugates.filter(
				(c) => getCorrugateWithinTolerance(c, machine, track) || c.id === "00000000-0000-0000-0000-000000000000",
			),
		].map((validCorrugate) =>
			res.push({
				key: `${selectedMachine.alias}-${track.trackNumber}_${validCorrugate.id}`,
				text: validCorrugate.alias,
				value: validCorrugate.id,
			}),
		);
		return res;
	};

	const allowTrackToBeChanged = () => {
		return allowChange !== false;
	};

	useEffect(() => {
		selectedMachine.tracks.forEach((t) => isValidTrackConfiguration(selectedMachine, t));
	}, [selectedMachine]);

	const isValidTrackConfiguration = (machine, track) => {
		if (track.loadedCorrugate === null) return true;

		let validTracks = getValidCorrugatesForTrack(machine, track);
		let result = validTracks.filter((c) => c.value === track.loadedCorrugate.id);
		let hasLoadedValidCorrugate = result.length > 0;
		if (!hasLoadedValidCorrugate)
			setTrackErrors((prevState) => {
				return { ...prevState, corrugateTrackErrors: [...prevState.corrugateTrackErrors, track.trackNumber] };
			});

		return result.length > 0;
	};

	const refresh = async () => {
		x4Api.getWithUrl(
			`tenants/${selectedMachine.tenantId}/machines/${selectedMachine.id}`,
			(data) => {
				selectedMachine.status = data.status;
				selectedMachine.physicalMachineSettings.gantryParameters.maximumCorrugateWidth =
					data.physicalMachineSettings.gantryParameters.maximumCorrugateWidth;
				setAllowChange(data.status === "Paused" || data.status === "Error");
			},
			(err) => {
				console.error(err);
			},
		);
	};

	useEffect(() => {
		const f = async () => await refresh();
		f();
		if (refreshInterval && refreshInterval > 0) {
			const interval = setInterval(refresh, refreshInterval);
			return () => clearInterval(interval);
		}
	}, [refreshInterval]);

	const storeOriginalTracks = (reset = false) => {
		const localtracks = [];
		if (reset || originalTracks.length === 0) {
			tracks.forEach((track) => {
				localtracks.push(track.loadedCorrugate.id);
			});
			setOriginalTracks(localtracks);
		}
	};
	useEffect(() => {
		storeOriginalTracks();
	}, [tracks]);

	useEffect(() => {
		checkSaveButtonEnabled();
	}, [originalTracks, succesfulSave, tracks]);

	useEffect(() => {
		if (succesfulSave && !allowChange) {
			setSuccesfulSave(false);
		}
	}, [allowChange]);

	const checkSaveButtonEnabled = () => {
		let isValidTrackChange = false;
		tracks.forEach((trk, idx) => {
			if (trk.loadedCorrugate.id !== originalTracks[idx]) {
				isValidTrackChange = true;
			}
		});

		const maxCasset = selectedMachine.physicalMachineSettings.gantryParameters.maximumCorrugateWidth;
		const trackOne = tracks.find((t) => t.trackNumber === 1)?.loadedCorrugate?.width ?? 0;
		const trackTwo = tracks.find((t) => t.trackNumber === 2)?.loadedCorrugate?.width ?? 0;
		const trackThree = tracks.find((t) => t.trackNumber === 3)?.loadedCorrugate?.width ?? 0;
		const trackFour = tracks.find((t) => t.trackNumber === 4)?.loadedCorrugate?.width ?? 0;

		let tempErrors = {
			casset1: false,
			casset2: false,
			duplicate: false,
			badTracks: [],
		};

		if (trackOne + trackTwo > maxCasset) {
			isValidTrackChange = false;
			tempErrors.casset1 = true;
		}

		if (trackThree + trackFour > maxCasset) {
			isValidTrackChange = false;
			tempErrors.casset2 = true;
		}

		for (let i = 0; i < tracks.length; i++) {
			let baseTrack = tracks[i];
			let duplicateCount = tracks.filter(
				(t) =>
					t.trackNumber !== baseTrack.trackNumber &&
					baseTrack?.loadedCorrugate?.id !== "00000000-0000-0000-0000-000000000000" &&
					t?.loadedCorrugate?.id === baseTrack?.loadedCorrugate?.id,
			);
			if (duplicateCount.length > 0) {
				tempErrors.duplicate = true;
				isValidTrackChange = false;
				tempErrors.badTracks.push(baseTrack.trackNumber);
			}
		}

		setTrackErrors(() => {
			return {
				casset1: tempErrors.casset1,
				casset2: tempErrors.casset2,
				duplicate: tempErrors.duplicate,
				corrugateTrackErrors: [...tempErrors.badTracks],
			};
		});

		if (succesfulSave && isValidTrackChange) setSuccesfulSave(false);

		setSaveButtonEnabled(isValidTrackChange);
	};

	const selectedCorrugateChanged = (corrugateId, track) => {
		const corrugate = corrugates.find((c) => c.id === corrugateId);
		if (corrugate !== null || corrugate !== undefined) {
			track.loadedCorrugate.alias = corrugate.alias;
			track.loadedCorrugate.id = corrugate.id;
			track.loadedCorrugate.quality = corrugate.quality;
			track.loadedCorrugate.thickness = convertToMM(corrugate.thickness);
			track.loadedCorrugate.width = convertToMM(corrugate.width);
		}
		setTracks(
			sortObjectsAscending([...tracks.filter((tt) => tt.trackNumber !== track.trackNumber), track], "trackNumber"),
		);
		setTrackErrors((prevState) => {
			return {
				...prevState,
				corrugateTrackErrors: prevState.corrugateTrackErrors?.filter((t) => t !== track.trackNumber),
			};
		});
	};

	const saveLoadedCorrugate = async () => {
		const updates = [];
		const machine = { ...selectedMachine };
		setSaveButtonEnabled(false);
		const task = x4Api.updateWithUrl(
			`tenants/${selectedMachine.tenantId}/machines/${machine.id}/configuretracks`,
			machine.tracks,
			() => {
				storeOriginalTracks(true);
				setSuccesfulSave(true);
			},
			(err) => {
				console.error(err);
				setSuccesfulSave(false);
				//set error state
			},
		);
		updates.push(task);
		await Promise.all(updates);
	};

	const translate = (key) => {
		return intl.formatMessage({ id: key });
	};
	return (
		<Fragment>
			<div>
				<Button
					primary={saveButtonEnabled}
					onClick={saveLoadedCorrugate}
					className={`${classes.saveButton} ${classes.centerVertically} ${classes.button}`}
					disabled={!saveButtonEnabled}
				>
					<span>{intl.formatMessage({ id: "Save" })}</span>
				</Button>
			</div>
			<Grid className={classes.semanticGrid} columns={16}>
				<Grid.Row className={classes.semanticGridRow}>
					<Grid.Column className={classes.machineBox} width={4}>
						<X4MachineCard
							alias={selectedMachine.alias}
							status={selectedMachine.status}
							classes={classes}
							translate={translate}
						/>
					</Grid.Column>
					<Grid.Column className={classes.semanticGridCol} width={5}>
						<TracksCard
							classes={classes}
							translate={translate}
							selectedMachine={selectedMachine}
							tracks={tracks}
							allowTrackToBeChanged={allowTrackToBeChanged}
							trackErrors={trackErrors}
							selectedCorrugateChanged={selectedCorrugateChanged}
							allowChange={allowChange}
							getValidCorrugatesForTrack={getValidCorrugatesForTrack}
						/>
					</Grid.Column>
					<Grid.Column className={classes.center} width={7}>
						<ChangeCorrugateStatus
							classes={classes}
							status={selectedMachine.status}
							translate={translate}
							machineId={selectedMachine.id}
							tenantId={selectedMachine.tenantId}
							hasBeenSaved={succesfulSave}
							trackErrors={trackErrors}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Fragment>
	);
};

export default X4ChangeCorrugate;

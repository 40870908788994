import { baseRoutes } from "../../../../../api/base-routes";
import { constructUrl, fetchData, manageCancelToken } from "../../../functions/api-request";

export async function getBucketCartonProductionByPlannedProductionSchedulesForSite(
	schedules,
	sites,
	machinesSelected,
	dateFilter,
) {
	if (!sites.length || !dateFilter?.id || !dateFilter.start) {
		return [];
	}

	const endpoint = `${baseRoutes.reporting}/v1/CartonProduction/CartonProductionBuckets`;
	const url = constructUrl(endpoint, dateFilter, false); // We assume `usesPlannedProduction` is false here
	const cancelToken = manageCancelToken(endpoint);

	const body = { schedules, sites, machines: machinesSelected };

	return fetchData(url, body, cancelToken);
}

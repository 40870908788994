import { UserContext } from "@/components/user-context";
import { useEffect, useState } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { identityApi } from "@/api/identitySdk.js";
import { useFeatureFlags } from "@/contexts/feature-flag-context.jsx";
import { useAuthentication } from "@/contexts/authentication-context.jsx";
import { allPermissions } from "@/views/configuration/roles/role-utils.js";
import { useQueryClient } from "@tanstack/react-query";
import LoadingScreen from "@/views/login/loading-screen.jsx";

const UserContextProvider = ({ children }) => {
	const [user, setUser] = useState({ role: [], _instantiated: false });
	const { setFeatureFlagIdentity, featureFlagEnabled } = useFeatureFlags();
	const reactPlugin = useAppInsightsContext();
	const { token, clearToken } = useAuthentication();
	const queryClient = useQueryClient();

	const hydrateUserData = async (newUserData) => {
		let additionalTenants;
		let licenseInfo = {};
		let finalSet;
		let role;
		let roles = "";
		let flags = {};
		await setFeatureFlagIdentity(newUserData);

		if (!featureFlagEnabled("hydrate-user", false)) {
			if (newUserData.role === undefined) newUserData.role = [];
			// if property is string, set to an array of one
			if (typeof newUserData?.role === "string" || newUserData?.role instanceof String)
				role = [newUserData?.role.toLowerCase()];
			// if it's an array of string, make them all lowercase
			else role = newUserData?.role?.map((r) => r.toLowerCase());
			// if the role array contains admin, give them all the permissions in the whole world
			if (role.includes("admin")) role = allPermissions(newUserData);

			// load in the user information via api calls
			let response = await identityApi.GetAdditionalTenants({
				UserId: newUserData.id,
			});

			if (response?.responseData?.additionalTenants) additionalTenants = response?.responseData?.additionalTenants;

			let licensingResponse = await identityApi.getLicensingInfo(newUserData.tid);

			if (licensingResponse?.responseData) licenseInfo = licensingResponse?.responseData;

			const permissionsResponse = await identityApi.getUserPermissions(newUserData.id);

			if (permissionsResponse?.data) {
				role = permissionsResponse?.data?.permissions.map((str) => str.toLowerCase());
				roles = permissionsResponse?.data?.roles.join(",") ?? "";
			}

			finalSet = {
				...newUserData,
				additionalTenants,
				licenseInfo,
				role,
				roles,
			};
		} else {
			let hydratedUserData = (await identityApi.hydrateUser()).data;

			role = hydratedUserData?.permissions;

			if (featureFlagEnabled("vaporware-ff-jwt", false)) {
				flags = hydratedUserData?.featureFlags;
			}

			additionalTenants = hydratedUserData?.userTenants?.map((x) => x.id);

			finalSet = {
				...newUserData,
				...hydratedUserData,
				...flags,
				userTenants: hydratedUserData?.userTenants,
				additionalTenants,
				role,
			};
		}
		return finalSet;
	};

	useEffect(() => {
		const initializeUserData = async () => {
			if (token === null) return; // auth context not initialized

			let newUserData = token;

			const isExpired = newUserData && newUserData?.exp - new Date().getTime() / 1000 <= 0;

			if (newUserData === undefined) {
				//unauthenticated user
				newUserData = { role: [], _instantiated: true };
			} else if (newUserData?.id != null && !isExpired) {
				// valid user, good token
				newUserData = {
					...newUserData,
					...(await hydrateUserData(newUserData)),
					_hydrated: true,
					_instantiated: true,
				};
			} // catch for expired token, bad user, bad token, etc..
			else {
				logout();
				return;
			}

			setUser(newUserData);
		};

		initializeUserData();
	}, [token]);

	useEffect(() => {
		if (user) {
			// when the user or app insights setup changes, instantiate app insights connection
			const appInsights = reactPlugin?.getAppInsights();
			if (appInsights) {
				const handler = appInsights.addTelemetryInitializer((env) => {
					env.data = env.data || {};

					env.data["company"] = user.tname;
					env.data["isPacksizeUser"] = user.email?.toLowerCase().endsWith("@packsize.com");
				});
				return () => handler.remove();
			}
		}
	}, [reactPlugin, user]);

	const logout = (_reasonForLogout, _expectedPage = "/login") => {
		let emptyUserObject = { role: [], _instantiated: true, _reasonForLogout, _expectedPage };
		setUser(emptyUserObject);
		queryClient.removeQueries();

		clearToken();
	};

	return (
		<UserContext.Provider value={{ currentUser: user, logout }}>
			{user?._instantiated === true ? children : <LoadingScreen />}
		</UserContext.Provider>
	);
};

export default UserContextProvider;

import axios from "axios";
import errorSeverity from "@/constants/errorSeverity";
import machineTypes from "@/constants/machineTypes";
import { readLocalStorage } from "@/api/local-storage";
import { useEffect, useState } from "react";
import { baseRoutes } from "@/api/base-routes";

const machineType = machineTypes.Fusion;

const toolAxisErrorCodes = {
	4007: "ToolAxisError EngineStall",
	4008: "ToolAxisError PositiveLimitSwitchReached",
	4009: "ToolAxisError NegativeLimitSwitchReached",
	5003: "ToolAxisError PositiveSoftwareLimitReached",
	5004: "ToolAxisError NegativeSoftwareLimitReached",
	5026: "ToolAxisError SpeedExceedsLimit",
	5027: "ToolAxisError AccelerationExceedsLimit",
	7215: "ToolAxisError PowerlineFailure",
};

const getToolAxisErrorCode = (errorArgument) => {
	const errorCode = toolAxisErrorCodes[errorArgument];

	if (!errorCode) {
		return "ToolAxisError";
	}

	return errorCode;
};

const longheadPositionErrorCodes = {
	0: "LongHeadPosition LoweringRoller",
	1: "LongHeadPosition NoSignalAtConnection",
	2: "LongHeadPosition NoSignalAtDisconnection",
	3: "LongHeadPosition OutsideOfDriftingLimit",
};

const getLongHeadPositionErrorCode = (errorArgument) => {
	const errorCode = longheadPositionErrorCodes[errorArgument];

	if (!errorCode) {
		return "LongHeadPosition";
	}

	return errorCode;
};

function createError(machineId, error) {
	let uiError = {
		machineId,
		machineType,
		severity: errorSeverity.error,
		code: error.message,
	};

	if (error.message === "CorrugateMismatch") {
		return {
			...uiError,
			instructionValues: { track: error.arguments[0], corrugate: error.arguments[1] },
		};
	}

	if (error.message === "OutOfCorrugate") {
		return {
			...uiError,
			instructionValues: { track: error.arguments[0] },
		};
	}

	if (error.message === "ToolAxisError") {
		let codes = error.arguments.filter((n) => n !== "0").join(", ");

		return {
			...uiError,
			code: getToolAxisErrorCode(error.arguments[0]),
			instructionValues: { code: codes },
		};
	}

	if (error.message === "LongHeadPosition") {
		return {
			...uiError,
			code: getLongHeadPositionErrorCode(error.arguments[0]),
			instructionValues: { longhead: error.arguments[1] },
		};
	}
	return uiError;
}

export default function FusionErrorsAndWarnings({ machines, onErrorsChanged }) {
	const token = readLocalStorage("BEARER");
	const [staleData, setStaleData] = useState(false);

	const machineIds = machines
		.filter((x) => x.machineType.toLowerCase() === machineType.toLowerCase())
		.map((x) => x.machineId);

	useEffect(() => {
		if (!staleData) {
			const handle = setTimeout(() => setStaleData(true), 5000);
			return () => clearTimeout(handle);
		}

		async function getErrorsAndWarnings(abortController) {
			for (const machineId of machineIds) {
				try {
					const response = await axios.get(`${baseRoutes.fusion}/api/v1/machines/${machineId}`, {
						headers: { Authorization: token },
						signal: abortController.signal,
					});

					if (abortController.signal.aborted) {
						continue;
					}

					if (response.status === 204 || response.data.error.code === 0) {
						onErrorsChanged(machineId, []);
						continue;
					}

					onErrorsChanged(machineId, [createError(machineId, response.data.error)]);
				} catch {
					console.error(`Failed to retrieve errors and warnings for ${machineType} machine: ${machineId}`);
				}
			}

			if (!abortController.signal.aborted) {
				setStaleData(false);
			}
		}

		const abortController = new AbortController();
		getErrorsAndWarnings(abortController);

		return () => {
			abortController.abort();
		};
	}, [machineIds, onErrorsChanged, staleData, token]);

	return <></>;
}

import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

export function useCancellingQuery({ queryKey, queryFn, refetchIntervalMs, retryAttempts = 3, ...props }) {
	const [refetchEnabled, setRefetchEnabled] = useState(true);
	const { failureCount, ...rest } = useQuery({
		queryKey,
		queryFn,
		refetchInterval: refetchEnabled ? refetchIntervalMs : false,
		retry: retryAttempts,
		...props,
	});

	if (failureCount > retryAttempts && refetchEnabled) setRefetchEnabled(false);

	return { refetchEnabled, ...rest };
}

import {
	Card,
	CardContent,
	CardDescription,
	CardGroup,
	CardHeader,
	CardMeta,
	Divider,
	Header,
	Search,
} from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useLocation, useNavigate } from "react-router";
import { FormattedMessage } from "react-intl";
import FailedRetry from "@/components/failed-retry";
import Loading from "@/components/loading";
import { UserContext } from "@/components/user-context";
import { search } from "@/utils/search";
import { useQueryClient } from "@tanstack/react-query";
import isEqual from "lodash.isequal";
import { publicSettingsQueryKey } from "@/contexts/public-settings-context";
import { identityApi } from "@/api/identitySdk.js";
import { useAuthentication } from "@/contexts/authentication-context.jsx";
import { useFeatureFlags } from "@/contexts/feature-flag-context.jsx";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	page: {
		backgroundColor: "#353430",
		display: "flex",
		flexDirection: "column",
	},
	redText: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: "#FF3743 !important",
		fontFamily: "Stolzl-Book",
		fontSize: "12px",
		fontStyle: "normal",
		fontWeight: "400",
		lineHeight: "normal",
	},
	metaPadding: {
		paddingBottom: "16px",
	},
	walmartLogo: {
		width: "132px",
		height: "32px",
		position: "absolute",
		left: "120px",
	},
	packsizeLogo: {
		width: "180px",
		"@media (max-width:750px)": {
			display: "none",
		},
	},
	mainTitleText: {
		textAlign: "center !important",
		fontFamily: "Stolzl !important",
		fontSize: "32px !important",
		fontStyle: "normal !important",
		fontWeight: "500 !important",
		lineHeight: "normal !important",
		margin: "0 !important",
	},
	title: {
		display: "flex",
		padding: "0 2rem",
		justifyContent: "space-between",
		textAlign: "center",
		lineHeight: "normal",
		"& > div": {
			width: "20%",
		},
		marginTop: "36px",
		marginBottom: "36px",
	},
	titleColumn: {
		minWidth: "150px",
		width: "300px !important",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	container: {
		height: "calc(100vh - 128px)",
		margin: "64px",
		backgroundColor: "white",
		borderRadius: "5px",
		minWidth: "460px",
	},
	cards: {
		marginLeft: "94px",
		marginRight: "94px",
		marginTop: "44px",
		height: "calc(100vh - 300px)",
		maxHeight: "calc(100vh - 300px)",
		overflowY: "auto",
		overflowX: "hidden",
		padding: "5px",
	},
	card: {
		minHeight: "88px",
		borderRadius: "6px",
		boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2) !important",
		backgroundColor: "#f2f4f5 !important",
		textAlign: "center",
		minWidth: "245px",
	},
	selectedCard: {
		minHeight: "88px",
		borderRadius: "6px",
		boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2) !important",
		backgroundColor: "#f2f4f5 !important",
		border: "1px solid #7ED321 !important",
		textAlign: "center",
		minWidth: "245px",
	},
	nocards: {
		textAlign: "center",
	},
	noWrap: {
		maxWidth: "100%",
		wordWrap: "break-word",
		textOverflow: "ellipsis",
	},
	header: {
		fontSize: "20px",
		fontWeight: 500,
		paddingTop: "15px",
		paddingLeft: "35px",
		paddingRight: "35px",
		color: "#353430",
		textOverflow: "ellipsis",
		minWidth: "245px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		minHeight: "40px",
	},
	content: {
		fontSize: "16px",
		border: "none !important",
		color: "#7c7b79",
	},
	version: {
		position: "absolute",
		bottom: "0px",
		color: "white",
		paddingLeft: "8px",
	},
	siteSearch: {
		margin: "0 !important",
		"& input": {
			borderRadius: "10px !important",
		},
		"& .ui.icon.input": {
			width: "100% !important",
		},

		"& .ui.icon.input > input": {
			paddingLeft: "3rem !important",
		},
		"& i": {
			marginLeft: "1rem !important",
		},
	},
	emptyDiv: {
		minHeight: "1em",
		minWidth: "2em",
	},
	logoContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	backContainer: {
		"& :hover": {
			cursor: "pointer !important",
		},
		display: "flex",
		alignItems: "center !important",
		justifyContent: "start",
	},
	backIcon: {
		minHeight: "36px !important",
		minWidth: "36px !important",
		"& path": {
			fill: "#000 !important",
		},
	},
}));

const SiteSelect = () => {
	const theme = useTheme();
	const { currentUser } = useContext(UserContext);
	const classes = useStyles({ theme });
	const [allList, setAllList] = useState([]);
	const [list, setList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [failed, setFailed] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [showBackButton, setShowBackButton] = useState(false);
	const navigate = useNavigate();
	const routeData = useLocation();
	const queryClient = useQueryClient();
	const { setToken } = useAuthentication();
	const { featureFlagEnabled } = useFeatureFlags();

	const loginSite = async (site) => {
		var data = await identityApi.getSite(site.tenantId ?? site.id);

		if (data.success) {
			queryClient.removeQueries({
				predicate: (query) => !isEqual(query.queryKey, publicSettingsQueryKey),
			});
			await setToken(data.responseData.access_token);
			navigate("/"); // may not need @todo
		} else {
			console.error("fail", data);
		}
	};

	const onSearch = (e, { value }) => {
		if (value.length < 3) setList(allList);
		else setList(search(value, allList, ["company", "siteId", "salesForceId", "siteTimeZone"]));
		setSearchTerm(value);
	};
	const load = () => {
		if (routeData?.state?.pathName !== "login") setShowBackButton(true);
		setFailed(false);
		setLoading(true);
		setList([]);

		if (!featureFlagEnabled("hydrate-user", false)) {
			identityApi.getSites().then((response) => {
				if (response.success) {
					setList(response.responseData);
					setAllList(response.responseData);
					setLoading(false);
				} else {
					setLoading(false);
					setFailed(true);
				}
			});
		} else {
			setAllList(currentUser?.userTenants);
			setList(currentUser?.userTenants);
			setLoading(false);
		}
	};
	useEffect(load, []);

	return (
		<span className={classes.page}>
			{loading ? (
				<div className={classes.container}>
					<Loading />
				</div>
			) : failed ? (
				<div className={classes.container}>
					<FailedRetry retry={load} />
				</div>
			) : (
				<div className={classes.container}>
					<div className={classes.title}>
						<div onClick={() => navigate(-1)} className={classes.titleColumn}>
							{showBackButton && (
								<div className={classes.backContainer}>
									<svg
										className={classes.backIcon}
										xmlns="http://www.w3.org/2000/svg"
										width="36"
										height="36"
										viewBox="0 0 36 36"
										fill="none"
									>
										<g clipPath="url(#clip0_218_1921)">
											<path
												d="M22.065 10.065C21.48 9.47997 20.535 9.47997 19.95 10.065L13.065 16.95C12.48 17.535 12.48 18.48 13.065 19.065L19.95 25.95C20.535 26.535 21.48 26.535 22.065 25.95C22.65 25.365 22.65 24.42 22.065 23.835L16.245 18L22.065 12.18C22.65 11.595 22.635 10.635 22.065 10.065Z"
												fill="#353430"
											/>
										</g>
										<defs>
											<clipPath id="clip0_218_1921">
												<rect width="36" height="36" fill="white" />
											</clipPath>
										</defs>
									</svg>
									<div>{currentUser.Company}</div>
								</div>
							)}
						</div>
						<Header className={`${classes.mainTitleText} ${classes.titleColumn}`}>
							<FormattedMessage id="Your Sites" />
						</Header>
						<Search
							input={{ icon: "search", iconPosition: "left" }}
							fluid
							value={searchTerm}
							onSearchChange={onSearch}
							showNoResults={false}
							className={`${classes.siteSearch} ${classes.titleColumn}`}
							placeholder={"Search for a site..."}
							autoFocus
						/>
					</div>
					<Divider />
					{list.length > 0 && (
						<div className={classes.cards}>
							<CardGroup centered>
								{list.map((l) => {
									let expiry = l?.expiresAt ? new Date(l?.expiresAt) : new Date().getTime();
									let now = new Date().getTime();
									let secondsToExpiry = Math.floor((expiry - now) / 1000);
									let minutesToExpiry = Math.floor(secondsToExpiry / 60);
									let hoursToExpiry = Math.floor(minutesToExpiry / 60);

									let timeString;

									if (hoursToExpiry > 0) {
										timeString = `${hoursToExpiry}h ${minutesToExpiry % 60}m`;
									} else if (minutesToExpiry > 0) {
										timeString = `${minutesToExpiry}m`;
									}

									return (
										<Card
											key={l.id}
											onClick={() => loginSite(l)}
											className={
												currentUser.tid === l.tenantId || currentUser.tenantId === l.id
													? classes.selectedCard
													: classes.card
											}
										>
											<CardContent>
												<CardHeader>{l.company}</CardHeader>
												<CardMeta>{l.siteId}</CardMeta>
												<CardDescription className={classes.noWrap}>{l.salesForceId}</CardDescription>
												<CardDescription extra>{l.siteTimeZone}</CardDescription>
											</CardContent>
											{l?.expiresAt && (
												<CardMeta className={[classes.redText, classes.metaPadding]}>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="20"
														height="20"
														viewBox="0 0 16 16"
														fill="none"
													>
														<path
															d="M8.66659 7.7335V5.3335C8.66659 5.14461 8.6027 4.98627 8.47492 4.8585C8.34714 4.73072 8.18881 4.66683 7.99992 4.66683C7.81103 4.66683 7.6527 4.73072 7.52492 4.8585C7.39714 4.98627 7.33325 5.14461 7.33325 5.3335V7.9835C7.33325 8.07239 7.34992 8.1585 7.38325 8.24183C7.41659 8.32516 7.46659 8.40016 7.53325 8.46683L9.73325 10.6668C9.85547 10.7891 10.011 10.8502 10.1999 10.8502C10.3888 10.8502 10.5444 10.7891 10.6666 10.6668C10.7888 10.5446 10.8499 10.3891 10.8499 10.2002C10.8499 10.0113 10.7888 9.85572 10.6666 9.7335L8.66659 7.7335ZM7.99992 14.6668C7.0777 14.6668 6.21103 14.4918 5.39992 14.1418C4.58881 13.7918 3.88325 13.3168 3.28325 12.7168C2.68325 12.1168 2.20825 11.4113 1.85825 10.6002C1.50825 9.78905 1.33325 8.92239 1.33325 8.00016C1.33325 7.07794 1.50825 6.21127 1.85825 5.40016C2.20825 4.58905 2.68325 3.8835 3.28325 3.2835C3.88325 2.6835 4.58881 2.2085 5.39992 1.8585C6.21103 1.5085 7.0777 1.3335 7.99992 1.3335C8.92214 1.3335 9.78881 1.5085 10.5999 1.8585C11.411 2.2085 12.1166 2.6835 12.7166 3.2835C13.3166 3.8835 13.7916 4.58905 14.1416 5.40016C14.4916 6.21127 14.6666 7.07794 14.6666 8.00016C14.6666 8.92239 14.4916 9.78905 14.1416 10.6002C13.7916 11.4113 13.3166 12.1168 12.7166 12.7168C12.1166 13.3168 11.411 13.7918 10.5999 14.1418C9.78881 14.4918 8.92214 14.6668 7.99992 14.6668ZM7.99992 13.3335C9.4777 13.3335 10.736 12.8141 11.7749 11.7752C12.8138 10.7363 13.3333 9.47794 13.3333 8.00016C13.3333 6.52239 12.8138 5.26405 11.7749 4.22516C10.736 3.18627 9.4777 2.66683 7.99992 2.66683C6.52214 2.66683 5.26381 3.18627 4.22492 4.22516C3.18603 5.26405 2.66659 6.52239 2.66659 8.00016C2.66659 9.47794 3.18603 10.7363 4.22492 11.7752C5.26381 12.8141 6.52214 13.3335 7.99992 13.3335Z"
															fill="#FF3743"
														/>
													</svg>
													<span>
														{hoursToExpiry || minutesToExpiry ? (
															<FormattedMessage id="Expires in" values={{ timeString }} />
														) : (
															<FormattedMessage id="Expires in seconds" />
														)}
													</span>
												</CardMeta>
											)}
										</Card>
									);
								})}
							</CardGroup>
						</div>
					)}
					{list.length === 0 && (
						<div className={classes.nocards}>
							<FormattedMessage id="No sites configured" />
						</div>
					)}
				</div>
			)}
		</span>
	);
};

export default SiteSelect;

import { baseRoutes } from "@/api/base-routes.js";

async function manuallyDetectNetworkSpeed() {
	const imageURL = `${baseRoutes.identity}/v1/utilities/networkSpeedTest`;

	const startTime = new Date().getTime();
	let response;
	try {
		response = await fetch(imageURL, {
			headers: {
				"Content-Type": "image/png",
			},
		});
	} catch (error) {
		console.error(error);
	}

	if (!response?.blob) return undefined;

	const blob = await response.blob();
	const endTime = new Date().getTime();
	const duration = (endTime - startTime) / 1000;
	const bitsLoaded = blob.size * 8;
	const speedBps = bitsLoaded / duration;
	const speedKbps = speedBps / 1024;
	const speedMbps = speedKbps / 1024;

	return speedMbps;
}
export async function getNetworkSpeed() {
	return await manuallyDetectNetworkSpeed();
}

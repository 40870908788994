import useCurrentUser from "@/hooks/use-current-user";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import useAxios from "@/api/useAxios";
import { ProductionContext } from "@/contexts/production-context";
import { BodyBook, BodyRegular, Header3 } from "@/components/typography";
import { getJobContentsString } from "@/views/production/orders/utils/utils";
import { FormattedMessage, useIntl } from "react-intl";
import DataTable from "react-data-table-component";
import Loading from "@/components/loading";
import { FlexCard, OrdersContainer } from "@/views/production/orders/orders";
import Flex from "@/components/flex";
import { format } from "date-fns";
import { isScanToTriggerConfigured } from "@/views/configuration/production-groups/manual-scan-to-produce-dropdown";
import { Divider, Icon } from "semantic-ui-react";
import { Link } from "react-router";
import styled from "styled-components";
import HistoryActionMenu from "@/views/production/orders/history/history-action-menu";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { baseRoutes } from "@/api/base-routes.js";

const StyledLink = styled(Link)`
	width: fit-content;
`;

function OrdersHistory() {
	const intl = useIntl();
	const currentUser = useCurrentUser();
	const { currentMachineGroup, currentProductionGroup } = useContext(ProductionContext);
	const auditApi = useAxios(`${baseRoutes.audit}/v1`, currentUser.token);
	const scanToTriggerConfigured = isScanToTriggerConfigured(currentProductionGroup.manualScanToProduce);

	const queryKey = ["orders-history", currentMachineGroup.id, currentUser.id];
	const queryFn = async () => {
		const response = await auditApi.getWithUrl(`Batch/history/byMachineGroup/${currentMachineGroup.id}/limit/25`);
		return response.data;
	};

	const { data, isLoading } = useQuery({
		queryKey,
		queryFn,
	});

	const reproduceSingleFromBatchMutation = useMutation({
		mutationFn: ({ batchId }) => {
			return axios.post(`/PipelineAuditApi/api/v1/Reproduce/PackagingSolution/byBatchId/${batchId}`, null, {
				headers: { Authorization: currentUser.token },
			});
		},
		onSuccess: (response) => {
			const message = intl.formatMessage(
				{
					id: "{alias} sent to queue",
					defaultMessage: "{alias} sent to queue",
				},
				{ alias: response.data.alias },
			);
			const action = (
				<Link to="/production/orders" style={{ marginRight: 8 }}>
					View
				</Link>
			);

			enqueueSnackbar(message, {
				variant: "customSuccess",
				actionComponent: action,
			});
		},
		onError: (error) => {
			console.error("Reproduce error", error);
			if (error.status === 404) {
				enqueueSnackbar(
					intl.formatMessage({
						id: "Unable to reproduce deleted or pending orders",
						defaultMessage: "Unable to reproduce deleted or pending orders",
					}),
					{
						variant: "customError",
					},
				);
			} else {
				enqueueSnackbar(
					intl.formatMessage({
						id: "Failed to reproduce a job from the batch",
						defaultMessage: "Failed to reproduce a job from the batch",
					}),
					{
						variant: "customError",
					},
				);
			}
		},
	});

	const columns = [
		{
			name: (
				<BodyRegular>
					<FormattedMessage id="Date" />
				</BodyRegular>
			),
			selector: (row) => <BodyBook>{format(row.statusLastUpdatedUTC, "Pp")}</BodyBook>,
			grow: 1.5,
		},
		{
			name: (
				<BodyRegular>
					<FormattedMessage id="Name" />
				</BodyRegular>
			),
			selector: (row) => <BodyBook>{row.alias}</BodyBook>,
			grow: 2,
		},
		{
			omit: !scanToTriggerConfigured,
			name: (
				<BodyRegular>
					<FormattedMessage id="Trigger Value" />
				</BodyRegular>
			),
			selector: (row) => <BodyBook>{row.licensePlateNumber}</BodyBook>,
			grow: 2,
		},
		{
			name: (
				<BodyRegular>
					<FormattedMessage id="Contents" />
				</BodyRegular>
			),
			selector: (row) => (
				<BodyBook>
					{getJobContentsString({
						cartonCountPerJob: row.cartonCountPerJob,
						labelCountPerJob: row.labelCountPerJob,
						cartonDimension: row.cartonDimension,
						intl,
					})}
				</BodyBook>
			),
			grow: 1,
		},
		{
			name: (
				<BodyRegular>
					<FormattedMessage id="Qty" />
				</BodyRegular>
			),
			selector: (row) => (
				<BodyBook>
					{row.producedCount}/{row.totalCount}
				</BodyBook>
			),
			grow: 0.25,
		},
		{
			name: (
				<BodyRegular>
					<FormattedMessage id="Status" />
				</BodyRegular>
			),
			selector: (row) => (
				<BodyBook>
					<FormattedMessage id={row.status} />
				</BodyBook>
			),
			grow: 0.75,
		},
		{
			cell: (row) => (
				<HistoryActionMenu
					batchId={row.batchId}
					reproduceSingle={(batchId) => reproduceSingleFromBatchMutation.mutate({ batchId })}
				/>
			),
			width: "56px",
		},
	];

	return (
		<OrdersContainer column gap={40}>
			<StyledLink to="/production/orders">
				<Flex gap={16}>
					<Icon name="chevron left" />
					<BodyRegular>
						<FormattedMessage id="Back to Orders" />
					</BodyRegular>
				</Flex>
			</StyledLink>
			<FlexCard>
				<Flex column style={{ padding: 16 }}>
					<Header3>
						<FormattedMessage id="Recently Produced" />
					</Header3>
				</Flex>
				<Divider style={{ margin: 0 }} />
				<DataTable
					keyField="batchId"
					noHeader
					progressPending={isLoading}
					progressComponent={<Loading />}
					columns={columns}
					data={data}
					customStyles={{
						responsiveWrapper: { style: { overflow: "visible", flexGrow: 1 } },
					}}
				/>
			</FlexCard>
		</OrdersContainer>
	);
}

export default OrdersHistory;

import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import useToken from "@/hooks/use-token.js";
import { ProductionContext } from "@/contexts/production-context.jsx";
import { UserContext } from "@/components/user-context.js";
import useAxios from "@/api/useAxios.js";
import { updateAllSolutionAttributeValuesToString } from "@/views/production/saved-jobs/utils/solution-transforms.js";
import { getSolutionTemplate } from "@/views/production/saved-jobs/utils/solution-template.js";
import { usePackagingSolutionPackagingDesigns } from "@/views/production/saved-jobs/hooks/use-packaging-solution-packaging-designs.js";
import { baseRoutes } from "@/api/base-routes.js";

export const SavedJobDependencyContext = createContext({
	locationOptions: [],
	location: {},
	setLocation: () => {},
	flutes: [],
	brands: [],
	packagingDesigns: [],
	lookupDesignName: () => {},
	lookupDesignXValues: () => {},
	sendToQueue: () => {},
	sendToQueuev2: () => {},
	sendSegmentedCartonSolution: () => {},
	sendSegmentedLabelSolution: () => {},
	loadLocationOptions: () => {},
});

const SavedJobDependencyProvider = ({ children }) => {
	const token = useToken();
	const { currentMachineGroup, currentProductionGroup } = useContext(ProductionContext);
	const { currentUser } = useContext(UserContext);
	const [locationOptions, setLocationOptions] = useState([]);
	const {
		packagingDesigns,
		isLoading: isLoadingPackagingDesigns,
		error: loadPackagingDesignsError,
		refetch: refetchPackagingDesigns,
	} = usePackagingSolutionPackagingDesigns();
	const [flutes, setFlutes] = useState([]);
	const [brands, setBrands] = useState([]);
	const [location, setInternalLocation] = useState({});
	const [packStationKey, setPackStationKey] = useState();
	const [isLoadingDependencies, setIsLoadingDependencies] = useState(true);
	const [errorDependencies, setErrorDependencies] = useState(undefined);

	const packagingSolutionsApi = useAxios(baseRoutes.import + "/v1/entities/packagingsolutions", token);
	const EmServiceApi = useAxios(`${baseRoutes.em}/api/v1/machines`, token);
	const importPackagingSolutionApi = useAxios(baseRoutes.import + "/v1/packagingsolutions", token);
	const corrugatesApi = useAxios("/corrugateapi/api/v1/corrugates", token);
	const sendToQueuev2 = useCallback(
		async (solutionToSend, setCallBack, errorCallBack) => {
			const solutionWithLocation = {
				...solutionToSend,
				location: { ...location, packStation: location.packStation?.toString() },
				quantity: parseInt(solutionToSend.attributes.quantity ?? "1"),
				batchId: crypto.randomUUID(),
			};
			solutionWithLocation.attributes.source = "PackageCreation";

			const toSend = updateAllSolutionAttributeValuesToString(solutionWithLocation);
			await importPackagingSolutionApi.postRequest([toSend], setCallBack, errorCallBack);
		},
		[importPackagingSolutionApi, location],
	);

	const sendToQueue = useCallback(
		async (solutionToSend, quantity, setCallBack, errorCallBack) => {
			solutionToSend.attributes.quantity = quantity;
			await sendToQueuev2(solutionToSend, setCallBack, errorCallBack);
		},
		[sendToQueuev2],
	);

	const loadDependencies = useCallback(async () => {
		setErrorDependencies(undefined);
		setIsLoadingDependencies(true);
		try {
			await Promise.all([corrugatesApi.getWithUrl("flutes", setFlutes), corrugatesApi.getWithUrl("brands", setBrands)]);
		} catch (e) {
			setErrorDependencies(e);
		} finally {
			setIsLoadingDependencies(false);
		}
	}, []);

	const sendSegmentedCartonSolution = useCallback(
		async (carton, setCallBack, errorCallBack) => {
			await sendToQueuev2(
				{
					...getSolutionTemplate(currentUser.Tenant),
					cartons: [carton],
				},
				setCallBack,
				errorCallBack,
			);
		},
		[currentUser.Tenant, sendToQueuev2],
	);

	const sendSegmentedLabelSolution = useCallback(
		async (label, setCallBack, errorCallBack) => {
			await sendToQueuev2(
				{
					...getSolutionTemplate(currentUser.Tenant),
					labels: [label],
				},
				setCallBack,
				errorCallBack,
			);
		},
		[currentUser.Tenant, sendToQueuev2],
	);

	const setLocation = useCallback((value) => setInternalLocation(value), []);

	const loadLocationOptions = useCallback(async () => {
		const getEmMachineLocations = async (machineId) => {
			let results = [];
			await EmServiceApi.getWithUrl(`${machineId}`, (data) => {
				if (data.crossConveyorConnected === true) {
					results = [
						{
							key: `${currentMachineGroup.id}_1`,
							text: `${data.alias} - 1`,
							value: {
								packStation: 1,
								machine: data.id,
								machineGroup: currentMachineGroup.alias,
								productionGroup: currentProductionGroup.alias,
								pickZone: null,
							},
						},
						{
							key: `${currentMachineGroup.id}_2`,
							text: `${data.alias} - 2`,
							value: {
								packStation: 2,
								machine: data.id,
								machineGroup: currentMachineGroup.alias,
								productionGroup: currentProductionGroup.alias,
								pickZone: null,
							},
						},
					];
				}
			});

			if (results.length > 0) {
				setPackStationKey(results[0].key);
				setLocation(results[0].value);
			}
			return results;
		};

		let options = [];
		if (Object.values(currentMachineGroup?.configuredMachinesWithMachineType).includes("Em")) {
			for (const [k, v] of Object.entries(currentMachineGroup.configuredMachinesWithMachineType)) {
				if (v === "Em") {
					options = await getEmMachineLocations(k);
					break;
				}
			}
		}

		setLocationOptions(options);
		if (options.length === 0) {
			setLocation({
				packStation: null,
				machine: null,
				machineGroup: currentMachineGroup.alias,
				productionGroup: currentProductionGroup.alias,
				pickZone: null,
			});
		}
	}, [
		EmServiceApi,
		currentMachineGroup.alias,
		currentMachineGroup.configuredMachinesWithMachineType,
		currentMachineGroup.id,
		currentProductionGroup.alias,
		setLocation,
	]);

	useEffect(() => {
		if (currentMachineGroup !== undefined && currentProductionGroup !== undefined) {
			const f = async () => await loadLocationOptions();
			f();
		}
	}, [currentMachineGroup, currentProductionGroup]);

	useEffect(() => {
		loadDependencies();
	}, [loadDependencies]);

	const lookupDesignName = useCallback(
		(id) => packagingDesigns.find((pd) => pd.designId === id)?.designName ?? id,
		[packagingDesigns],
	);

	const lookupDesignXValues = useCallback(
		(id) =>
			packagingDesigns
				.find((pd) => pd.designId === id)
				?.designParameters.sort((p1, p2) => {
					return p1.name.localeCompare(p2.name, undefined, {
						numeric: true,
					});
				}) ?? [],
		[packagingDesigns],
	);

	const contextValue = useMemo(
		() => ({
			locationOptions,
			location,
			packStationKey,
			setPackStationKey,
			setLocation,
			flutes,
			brands,
			packagingDesigns,
			lookupDesignName,
			lookupDesignXValues,
			sendToQueue,
			sendToQueuev2,
			sendSegmentedCartonSolution,
			sendSegmentedLabelSolution,
			loadLocationOptions,
			packagingSolutionsApi,
			isLoadingDependencies: isLoadingDependencies || isLoadingPackagingDesigns,
			loadDependencies: () => {
				loadDependencies();
				refetchPackagingDesigns();
			},
			errorDependencies: errorDependencies || loadPackagingDesignsError,
		}),
		[
			brands,
			errorDependencies,
			flutes,
			isLoadingDependencies,
			isLoadingPackagingDesigns,
			loadDependencies,
			loadLocationOptions,
			loadPackagingDesignsError,
			location,
			locationOptions,
			lookupDesignName,
			lookupDesignXValues,
			packStationKey,
			packagingDesigns,
			packagingSolutionsApi,
			refetchPackagingDesigns,
			sendSegmentedCartonSolution,
			sendSegmentedLabelSolution,
			sendToQueue,
			sendToQueuev2,
			setLocation,
		],
	);

	return <SavedJobDependencyContext.Provider value={contextValue}>{children}</SavedJobDependencyContext.Provider>;
};

export default SavedJobDependencyProvider;

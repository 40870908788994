import moment from "moment-timezone";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Icon, List, Menu, Popup } from "semantic-ui-react";
import { UserContext } from "@/components/user-context";
import { useLocalStorage } from "@/views/reporting/custom-hooks/local-storage-hook";
import { ReactComponent as CalendarImage } from "@/views/reporting/img/calendar-icon.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import { useLilius } from "use-lilius";
import RadioButton from "./radio-button";
import {
	convertToNewTimeZoneMaintainTime,
	format,
	getTimeZoneOffset,
	getTodayMomentStartOfDay,
	isDateToday,
	isDaySelected,
	moveEndDateToStartNextDayUnlessTodayOrPreset,
	parseDateToMomentStartOfDay,
	toMoment,
} from "./functions/date-moment";
import { isFeatureFlagEnabled } from "@/utils/auth";
import { getCurrentTimeZoneForSite } from "./services/API/Reports/identity-api";

const useStyles = createUseStyles({
	dropdownHeading: {
		display: "flex",
		alignItems: "center",
	},
	inputStyle: {
		backgroundColor: "#e0e0e0 !important",
		width: "135px !important",
		maxWidth: "182px !important",
		margin: "0em",
		height: "44px",
		flex: "1 0 auto",
		outline: "none",
		"-webkit-tap-highlight-color": "rgba(255, 255, 255, 0)",
		"text-align": "center",
		"line-height": "1.4",
		"font-family": "'Stolzl', 'Helvetica Neue', Arial, Helvetica, sans-serif",
		fontSize: "16px",
		background: "#d36060",
		border: "1px solid rgba(34, 36, 38, 0.15)",
		color: "#353430",
		"border-radius": "6px",
		transition: "box-shadow 0.1s ease, border-color 0.1s ease",
		"box-shadow": "none",
		"&:focus": {
			border: "2px solid #294652 !important",
		},
	},
	datePicker: {
		borderRadius: "8px !important",
	},
	fromTo: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	selectedDay: {
		backgroundColor: "#294652 !important",
		height: "scale(1.5)",
		width: "scale(1.5)",
		margin: "0",
		borderRadius: "0",
	},
	inRangeDay: {
		backgroundColor: "#D4DADC !important",
		width: "scale(1.2)",
		margin: "0",
		border: "0",
		borderRadius: "0",
		color: "#353430",
	},
	doneButton: {
		color: "#fff !important",
		float: "right",
		marginRight: "0px !important",
	},
	customButtonEnabled: {
		backgroundColor: "#294652 !important",
	},
	customButtonDisabled: {
		backgroundColor: "#e0e0e0 !important",
	},
	calendarImage: {
		marginLeft: "15px !important",
		marginRight: "6px",
	},
	dropdownIcon: {
		marginRight: "15px !important",
	},
	calendar: {
		border: "none",
		fontFamily: "'Stolzl', 'Helvetica Neue', Arial, Helvetica, sans-serif",
	},
	month: {
		backgroundColor: "red !important",
	},
	alignCalendar: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		"& > div": {
			flexGrow: "1",
			width: "100%",
			display: "flex",
			justifyContent: "space-between",
		},
	},
	calendarGrid: {
		display: "flex",
		flexDirection: "column",
		gap: "2px",
		margin: "0",
		marginBottom: "12px",
		"& > div": {
			textTransform: "uppercase",
			fontFamily: "Stolzl",
			fontSize: "14px",
			fontWeight: "normal",
			fontStretch: "normal",
			fontStyle: "normal",
			lineHeight: "1.4",
			letterSpacing: "normal",
			display: "flex",
			flexDirection: "row",
			margin: "0",
			justifyContent: "space-evenly",
			alignItems: "center",
			"& > div": {
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "32px",
				"& > span": {
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					fontFamily: "Stolzl",
					fontSize: "16px",
					fontWeight: "normal",
					fontStretch: "normal",
					fontStyle: "normal",
					lineHeight: "1.4",
				},
			},
		},
	},
	calendarHeader: {
		fontFamily: "Stolzl",
		fontSize: "21px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "1.4",
		letterSpacing: "normal",
		marginTop: "36px",
		marginBottom: "10px",
	},
	calendarLine: {
		"& > $inRange:first-child": {
			borderTopLeftRadius: "8px",
			borderBottomLeftRadius: "8px",
		},
		"& > $inRange:last-child": {
			borderTopRightRadius: "8px",
			borderBottomRightRadius: "8px",
		},
	},
	selected: {
		"& > span": {
			backgroundColor: "#294652",
			borderRadius: "50%",
			height: "30px",
			width: "30px",
			color: "#fff",
		},
	},
	selectedFirst: {
		background: "linear-gradient(to left, #D4DADC 50%, white 50%)",
		composes: ["$selected"],
	},
	selectedLast: {
		background: "linear-gradient(to left, white 50%, #D4DADC 50%)",
		composes: ["$selected"],
	},
	inRange: {
		backgroundColor: "#D4DADC",
	},
	startOfWeek: {
		borderRadius: "2px 0 0 2px",
	},
	endOfMonth: {
		borderRadius: "0 2px 2px 0",
	},
	today: {
		"& > span": {
			backgroundColor: "#f2f2f2",
			borderRadius: "50%",
			height: "30px",
			width: "30px",
		},
	},
	future: {
		"& > span": {
			backgroundColor: "#f2f2f2",
			borderRadius: "50%",
			height: "30px",
			width: "30px",
			opacity: "0.4",
		},
	},
});

const options = ["Today", "Yesterday", "Last7Days", "Last30Days", "Custom"];

function buildPresetDateOptions(selectedTimeOption, timeZone) {
	const now = moment.tz(timeZone);
	const startOfToday = now.clone().startOf("day");

	switch (selectedTimeOption) {
		case "Yesterday": {
			const yesterday = startOfToday.clone().subtract(1, "days");
			return {
				start: yesterday,
				end: startOfToday,
				offset: getTimeZoneOffset(timeZone, startOfToday),
				id: selectedTimeOption,
				timeZoneId: timeZone,
			};
		}
		case "Last7Days": {
			const sevenDaysAgo = startOfToday.clone().subtract(6, "days");
			return {
				start: sevenDaysAgo,
				end: now,
				offset: getTimeZoneOffset(timeZone, now),
				id: selectedTimeOption,
				timeZoneId: timeZone,
			};
		}
		case "Last30Days": {
			const thirtyDaysAgo = startOfToday.clone().subtract(29, "days");
			return {
				start: thirtyDaysAgo,
				end: now,
				offset: getTimeZoneOffset(timeZone, now),
				id: selectedTimeOption,
				timeZoneId: timeZone,
			};
		}
		case "Today":
		default: {
			return {
				start: startOfToday,
				end: now,
				offset: getTimeZoneOffset(timeZone, now),
				id: selectedTimeOption,
				timeZoneId: timeZone,
			};
		}
	}
}

function DatePickerDropdown({ dateOptions, setDateOptions, datePickerWidth }) {
	const widthDatePicker = datePickerWidth + "px";
	const SELECTED_DATE_RANGE = "SELECTED_DATE_RANGE";
	const [dateOptionsLocalStorage, setDateOptionsLocalStorage] = useLocalStorage(SELECTED_DATE_RANGE);
	const initStartDate = moment(dateOptions.start).startOf("day");
	const initEndDate = moment(dateOptions.end);
	const [open, setOpen] = useState(false);
	const classes = useStyles();
	const [isCustomShown, setIsCustomShown] = useState(dateOptions.id === "Custom");
	const [customStartDate, setCustomStartDate] = useState(initStartDate);
	const [customEndDate, setCustomEndDate] = useState(initEndDate);
	const [optionSelected, setOptionSelected] = useState(dateOptions.id);
	const [timeZone, setTimeZone] = useState("");
	const intl = useIntl();
	const { currentUser } = useContext(UserContext);

	const startRef = useRef(null);
	const endRef = useRef(null);

	const { calendar, deselect, select, selected, viewNextMonth, viewPreviousMonth } = useLilius({
		numberOfMonths: 1,
		// @ts-ignore
		selected: [dateOptions?.start?.toDate(), dateOptions?.end?.toDate()],
	});

	useEffect(() => {
		async function getData() {
			// react state has been set previously
			if (dateOptions.id) {
				dateOptions.offset = getTimeZoneOffset(timeZone, dateOptions.end);
				dateOptions.timeZoneId = timeZone;
				setDateOptions(dateOptions);
				return;
			}

			const localDateOptions = dateOptionsLocalStorage ? parseDateOptions(dateOptionsLocalStorage) : { id: "Today" };

			// local storage or default
			if (localDateOptions.id === "Custom") {
				handleCustomDateOptions(localDateOptions);
			} else {
				handleDatePresetOptions(localDateOptions.id);
			}
		}

		if (timeZone) getData();
		function handleDatePresetOptions(selectedTimeOption) {
			const newDateOptions = buildPresetDateOptions(selectedTimeOption, timeZone);

			setDateOptions(newDateOptions);
			setOptionSelected(newDateOptions.id);
			select([newDateOptions.start.toDate(), newDateOptions.end.toDate()], true);
			setCustomStartDate(newDateOptions.start);
			setCustomEndDate(newDateOptions.end);
		}

		function handleCustomDateOptions(newDateOptions) {
			newDateOptions.start = convertToNewTimeZoneMaintainTime(
				newDateOptions.start,
				newDateOptions.timeZoneId,
				timeZone,
			);
			newDateOptions.end = convertToNewTimeZoneMaintainTime(newDateOptions.end, newDateOptions.timeZoneId, timeZone);

			newDateOptions.timeZoneId = timeZone;
			newDateOptions.offset = getTimeZoneOffset(timeZone, newDateOptions.end);

			setDateOptions(newDateOptions);
			setOptionSelected(newDateOptions.id);
			select([newDateOptions.start.toDate(), newDateOptions.end.toDate()], true);
			setCustomStartDate(newDateOptions.start);
			setCustomEndDate(newDateOptions.end);
			setIsCustomShown(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeZone]);

	useEffect(() => {
		async function onLoad() {
			let siteTimeZone = moment.tz.guess();
			if (!isFeatureFlagEnabled(currentUser, "pack-net-reporting-use-site-timezone")) {
				setTimeZone(siteTimeZone);
				return;
			}

			const response = await getCurrentTimeZoneForSite(currentUser.Tenant);
			if (response.success) {
				siteTimeZone = response.data?.siteTimeZone ?? moment.tz.guess();
				setTimeZone(siteTimeZone);
			}
		}

		if (currentUser) onLoad();
	}, [currentUser]);

	function serializeDateOptions(dateOptionsData) {
		const serializedStart = format(dateOptionsData.start);
		const serializedEnd = format(dateOptionsData.end);

		return JSON.stringify({
			...dateOptionsData,
			start: serializedStart,
			end: serializedEnd,
		});
	}

	function parseDateOptions(dateOptionsString) {
		const serializedDetails = JSON.parse(dateOptionsString);

		const start = toMoment(serializedDetails.start);
		const end = toMoment(serializedDetails.end);
		return {
			...serializedDetails,
			start,
			end,
		};
	}

	async function handleDoneComplete() {
		if (!moment.isMoment(customEndDate) || !customEndDate.isValid()) return;

		const tempOptions = {
			start: customStartDate,
			offset: getTimeZoneOffset(timeZone, customEndDate),
			end: customEndDate,
			id: "Custom",
			timeZoneId: timeZone,
		};

		const newDateOptions = moveEndDateToStartNextDayUnlessTodayOrPreset(tempOptions, timeZone);

		setOpen(false);
		setDateOptionsLocalStorage(serializeDateOptions(newDateOptions));
		setDateOptions(newDateOptions);
	}

	function handleChange(id) {
		setOptionSelected(id);
		if (id === "Custom") {
			setIsCustomShown(true);
		} else {
			const selectedDateOptions = buildPresetDateOptions(id, timeZone);

			const endDateDisplayValue =
				id === "Today" ? selectedDateOptions.end : selectedDateOptions.end.clone().add(-1, "days");

			setDateOptionsLocalStorage(serializeDateOptions(selectedDateOptions));
			setDateOptions(selectedDateOptions);
			setCustomStartDate(selectedDateOptions.start);
			setCustomEndDate(endDateDisplayValue);
			select([selectedDateOptions.start.toDate(), endDateDisplayValue.toDate()], true);

			setIsCustomShown(false);
			setOpen(false);
		}
	}

	const paddingStyle = {
		paddingRight: "15px !important",
		paddingLeft: "15px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	};

	function getDayClass(day) {
		const dayMoment = parseDateToMomentStartOfDay(day, timeZone);
		const tempSelected = selected.map((date) => parseDateToMomentStartOfDay(date, timeZone));

		if (isDaySelected(dayMoment, tempSelected)) {
			return getSelectedClass(dayMoment, tempSelected, classes);
		}

		if (isDayInRange(dayMoment, tempSelected)) {
			return classes.inRange;
		}

		if (isDateToday(dayMoment, timeZone)) {
			return classes.today;
		}

		if (isFuture(dayMoment, timeZone)) {
			return classes.future;
		}

		return "";
	}

	function getSelectedClass(dayMoment, tempSelected, classes) {
		if (tempSelected.length === 1 || (tempSelected.length === 2 && tempSelected[0].isSame(tempSelected[1], "day"))) {
			return classes.selected;
		}
		if (tempSelected[0].isSame(dayMoment, "day")) {
			return classes.selectedFirst;
		}
		return classes.selectedLast;
	}

	function isDayInRange(dayMoment, tempSelected) {
		//TODO: Review to make sure we are using the right signatura
		const startInclusiveEndNonInclusive = "[)";
		return (
			tempSelected.length === 2 &&
			dayMoment.isBetween(tempSelected[0], tempSelected[1], null, startInclusiveEndNonInclusive)
		);
	}

	function isFuture(dayMoment, timeZone) {
		const today = getTodayMomentStartOfDay(timeZone);
		return dayMoment.isAfter(today, "day");
	}

	function handleCalendarClick(day) {
		const dayMoment = parseDateToMomentStartOfDay(day, timeZone);
		if (dayMoment.isAfter(getTodayMomentStartOfDay(timeZone), "day")) return;

		if (selected.length === 0) {
			select(dayMoment.toDate());
			setCustomStartDate(dayMoment);
			return;
		}

		if (selected.length >= 2) {
			selected.forEach((d) => deselect(d));
			setCustomEndDate(null);
			select(dayMoment.toDate());
			setCustomStartDate(dayMoment);
			return;
		}

		const sorted = [parseDateToMomentStartOfDay(selected[0], timeZone), dayMoment].sort((a, b) =>
			a.isBefore(b) ? -1 : 1,
		);
		select(
			sorted.map((d) => d.toDate()),
			true,
		);
		setCustomStartDate(sorted[0]);
		setCustomEndDate(sorted[1]);
	}

	const timeZoneLabel = timeZone ? ` (${timeZone})` : "";

	return (
		<Menu className={classes.datePicker} fluid widths={1}>
			<Popup
				style={{ width: widthDatePicker }}
				flowing
				basic
				on="click"
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				open={open}
				position="bottom right"
				trigger={
					<Menu.Item style={paddingStyle}>
						<div className={classes.dropdownHeading}>
							<CalendarImage className={classes.calendarImage} />
							<FormattedMessage id={dateOptions.id ? dateOptions.id : "Date Range"} />
							{timeZoneLabel}
						</div>
						<Icon name="dropdown" className={classes.dropdownIcon} />
					</Menu.Item>
				}
			>
				<List relaxed="very" size="medium">
					{options.map((option) => (
						<List.Item key={option}>
							<RadioButton checked={optionSelected === option} onChange={() => handleChange(option)}>
								<FormattedMessage id={option} /> {option === "Today" && `(${intl.formatMessage({ id: "Default" })})`}
							</RadioButton>
						</List.Item>
					))}
					{isCustomShown && (
						<List.Item key="custom">
							<div className={classes.fromTo}>
								<input
									className={classes.inputStyle}
									value={customStartDate ? moment.tz(customStartDate, timeZone).format("L") : ""}
									readOnly
									ref={startRef}
								/>
								<FormattedMessage id="to" />
								<input
									className={classes.inputStyle}
									value={customEndDate ? moment.tz(customEndDate, timeZone).format("L") : ""}
									readOnly
									ref={endRef}
								/>
							</div>
							<div className={classes.alignCalendar}>
								<div className={classes.calendarHeader}>
									<Icon onClick={viewPreviousMonth} name="angle left" />

									{calendar.map(([[firstDay]]) => {
										const targetDate = moment.tz(firstDay, timeZone);
										targetDate.add(6, "days");

										const year = targetDate.format("YYYY");
										const month = targetDate.format("MMMM");
										return <span key={targetDate.toString()}>{`${intl.formatMessage({ id: month })} ${year}`}</span>;
									})}

									<Icon onClick={viewNextMonth} name="angle right" />
								</div>
								{calendar.map((month, i) => (
									<div className={classes.calendarGrid} key={`calendar-row-${i}`}>
										<div>
											{month[0].map((day) => {
												const daysOfWeek = [
													"Sunday Abbrev",
													"Monday Abbrev",
													"Tuesday Abbrev",
													"Wednesday Abbrev",
													"Thursday Abbrev",
													"Friday Abbrev",
													"Saturday Abbrev",
												].map((d) => intl.formatMessage({ id: d }));
												return <div key={`${day}`}>{daysOfWeek[moment.tz(day, timeZone).day()]}</div>;
											})}
										</div>

										{month.map((week) => (
											<div key={`month-${month[0][0]}-week-${week[0]}`} className={classes.calendarLine}>
												{week.map((day) => (
													<div className={getDayClass(day)} key={`${day}`} onClick={() => handleCalendarClick(day)}>
														<span>{moment.tz(day, timeZone).format("DD")}</span>
													</div>
												))}
											</div>
										))}
									</div>
								))}
							</div>
							<div>
								<Button
									onClick={handleDoneComplete}
									disabled={selected.length !== 2}
									className={
										selected.length === 2
											? `${classes.doneButton} ${classes.customButtonEnabled}`
											: `${classes.doneButton} ${classes.customButtonDisabled}`
									}
								>
									<FormattedMessage id="Done" />
								</Button>
							</div>
						</List.Item>
					)}
				</List>
			</Popup>
		</Menu>
	);
}

export default DatePickerDropdown;

import { Button, Dropdown, DropdownDivider, DropdownItem, DropdownMenu, Icon } from "semantic-ui-react";
import Flex from "@/components/flex";
import { useContext, useState } from "react";
import { ProductionContext } from "@/contexts/production-context";
import { MachineGroupContext } from "@/contexts/machine-group/machine-group-context";
import { ProductionGroupContext } from "@/contexts/production-group/production-group-context";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmModal from "@/components/confirm-modal";
import useAxios from "@/api/useAxios";
import styled from "styled-components";
import { UserContext } from "@/components/user-context";
import MachineGroupStatusIcon from "./machine-group-status-icon";
import useToken from "@/hooks/use-token";
import { baseRoutes } from "@/api/base-routes.js";

const StyledDropdown = styled(Dropdown)`
	&&& {
		display: flex;
		align-items: center;
		min-width: 136px;
		gap: 8px;
		border: 1px solid ${(props) => props.theme.colors.border1};
		border-radius: 6px;
		justify-content: space-around;
		padding: 8px;
	}
`;

export const toggleMachineGroupPlay = (currentMachineGroup, MachineGroupApi) => {
	if (currentMachineGroup) {
		MachineGroupApi.updateWithUrl(
			`${currentMachineGroup.id}/enableProduction`,
			{ enabled: !currentMachineGroup.productionEnabled },
			() => {},
			(err) => console.error("Error enabling/disabling production", err),
		);
	}
};

function MachineGroupControl() {
	const token = useToken();

	const intl = useIntl();

	const { machineGroups, currentMachineGroup } = useContext(ProductionContext);
	const { changeMachineGroup } = useContext(MachineGroupContext);
	const { removeMachineGroupFromProductionGroup } = useContext(ProductionGroupContext);
	const { currentUser } = useContext(UserContext);

	const MachineGroupApi = useAxios(`${baseRoutes.machineGroup}/v1/MachineGroups`, token);

	const [isLeaveModalOpened, setIsLeaveModalOpened] = useState(false);
	const [isChangeMGModalOpened, setIsChangeMGModalOpened] = useState(false);
	const [isChangePGModalOpened, setIsChangePGModalOpened] = useState(false);
	const [changeToMg, setChangeToMg] = useState(null);

	if (!currentMachineGroup) {
		return null;
	}

	const machineOnline = currentMachineGroup.status.toLowerCase() === "online";
	const machineGroupInPlay = currentMachineGroup.productionEnabled;
	const playButtonEnabled = machineGroupInPlay || machineOnline;

	const handleMachineGroupChange = (machineGroupId) => {
		const newMachineGroup = machineGroups.find((mg) => mg.id === machineGroupId);
		if (newMachineGroup) {
			setChangeToMg(newMachineGroup);
			setIsChangeMGModalOpened(true);
		} else setChangeToMg(null);
	};

	return (
		<Flex gap={8}>
			<ConfirmModal
				open={isLeaveModalOpened}
				onClose={async (response) => {
					if (response) changeMachineGroup(undefined);
					setIsLeaveModalOpened(false);
				}}
				titleText={intl.formatMessage({
					id: "Leave Machine Group",
				})}
				detailText="Would you like to leave the operator screen for this machine group?"
				confirmText="Yes"
				cancelText="Cancel"
				size="large"
			/>
			<ConfirmModal
				open={isChangePGModalOpened}
				onClose={async (response) => {
					if (response) {
						await removeMachineGroupFromProductionGroup(currentMachineGroup);
					}
					setIsChangePGModalOpened(false);
				}}
				titleText={intl.formatMessage({
					id: "Remove Machine Group from Production Group",
				})}
				detailText="Would you like to remove this machine group from the current production group This can negatively impact the way jobs are produced"
				confirmText="Yes"
				cancelText="Cancel"
				size="large"
			/>
			<ConfirmModal
				open={isChangeMGModalOpened}
				formatMessages={false}
				onClose={async (response) => {
					if (response) {
						changeMachineGroup(changeToMg);
						setChangeToMg(null);
					}
					setIsChangeMGModalOpened(false);
				}}
				titleText={intl.formatMessage({
					id: "Change Machine Group operator screen",
				})}
				detailText={`Would you like to load the operator screen for the machine group ${
					changeToMg?.alias || "unknown"
				}?`}
				confirmText={intl.formatMessage({ id: "Yes" })}
				cancelText={intl.formatMessage({ id: "Cancel" })}
				size="large"
			/>
			<StyledDropdown
				text={currentMachineGroup.alias}
				defaultValue={currentMachineGroup.id}
				icon={<Icon style={{ display: "flex", alignItems: "center", margin: 0, width: "auto" }} name="angle down" />}
			>
				<DropdownMenu>
					{machineGroups.map((mg) => (
						<DropdownItem text={mg.alias} value={mg.id} key={mg.id} onClick={() => handleMachineGroupChange(mg.id)} />
					))}
					<DropdownDivider />
					<DropdownItem
						key="ChangeMachineGroup"
						text={intl.formatMessage({ id: "Leave Machine Group" })}
						value="ChangeMachineGroup"
						onClick={() => setIsLeaveModalOpened(true)}
					/>
					{currentUser.role.includes("changeMachineGroupsProductionGroup".toLocaleLowerCase()) && (
						<DropdownItem
							key="ChangeProductionGroup"
							text={intl.formatMessage({ id: "Change Production Group" })}
							onClick={() => setIsChangePGModalOpened(true)}
						/>
					)}
				</DropdownMenu>
			</StyledDropdown>
			<MachineGroupStatusIcon machineGroupStatus={currentMachineGroup.status} border>
				<span>
					<FormattedMessage id={currentMachineGroup.status} />
				</span>
			</MachineGroupStatusIcon>
			<Button
				basic
				icon
				disabled={!playButtonEnabled}
				onClick={() => toggleMachineGroupPlay(currentMachineGroup, MachineGroupApi)}
				style={{ margin: 0 }}
			>
				<Icon
					name={currentMachineGroup.productionEnabled ? "pause circle outline" : "play circle outline"}
					color={currentMachineGroup.productionEnabled ? "grey" : "olive"}
					size="large"
					style={{ height: "1em" }}
				/>
			</Button>
		</Flex>
	);
}

export default MachineGroupControl;

import { Button, Checkbox, Form, Grid, Icon, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { UserContext } from "@/components/user-context";
import Flex from "@/components/flex";
import { enqueueSnackbar } from "notistack";
import { ReactComponent as Copy } from "@/assets/images/copy.svg";
import { identityApi } from "@/api/identitySdk.js";
import { useFeatureFlags } from "@/contexts/feature-flag-context.jsx";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	header: {
		border: "0 !important",
		alignItems: "center",
		padding: "24px 32px !important",
		margin: "0 !important",
		backgroundColor: `${theme.colors.light} !important`,
		borderRadius: "0px !important",
		minHeight: "96px",
	},
	headerText: {
		color: `${theme.colors.text} !important`,
		fontWeight: "normal !important",
		fontSize: "40px !important",
		margin: "0 !important",
		lineHeight: "36px !important",
		letterSpacing: "-.5px",
	},
	form: {
		border: "unset !important",
		padding: "75px 170px !important",
	},
	readonly: {
		opacity: "1 !important",
		backgroundColor: "#f2f4f5",
		color: "#7c7b79",
	},
	connectionStatus: {
		marginRight: "10px !important",
		marginBottom: "10px !important",
	},
	licensingServer: {
		marginTop: "16px",
		"& div": {
			marginTop: "16px",
		},
	},
}));

const defaultSettings = {
	unitOfMeasure: "inches",
	reportingConnected: false,
};
const ServerSettingsTab = forwardRef((props, ref) => {
	const { setIsDirty } = props;
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [settings, setSettings] = useState(defaultSettings);
	const [siteTimeZone, setSiteTimeZone] = useState({});
	const { currentUser } = useContext(UserContext);
	const { flagsAvailable } = useFeatureFlags();
	const showFailStagingConfig = currentUser["ff-fail-staging-configuration"];
	const showConnectionTest = currentUser["ff-feature-flag-connection-test"];

	useImperativeHandle(ref, () => ({
		save: async () => {
			try {
				await identityApi.updateSettings(settings);
			} catch (e) {
				console.error(e);
			}
			enqueueSnackbar(intl.formatMessage({ id: "Saved", defaultMessage: "Saved" }), {
				variant: "customSuccess",
			});
		},
	}));

	const load = () => {
		identityApi.getTenantTimezone().then((x) => {
			setSiteTimeZone(x.responseData);
		});
		identityApi.getSettings().then((data) => {
			if (data.success) {
				setSettings(data.responseData);
			} else {
				// setDisplayState("failed");
			}
		});
	};

	useEffect(load, []);

	return (
		<Segment basic attached className={classes.form}>
			<Form error>
				<Grid relaxed="very" padded>
					<Grid.Row columns={2}>
						<Grid.Column>
							<FormattedMessage id="Unit of Measure" />
							<Form.Select
								placeholder={intl.formatMessage({ id: "Unit of Measure" })}
								value={settings.unitOfMeasure === "inches" ? "inches" : "mm"}
								onChange={(e, { value }) => {
									setSettings({ ...settings, unitOfMeasure: value });
									setIsDirty(true);
								}}
								options={[
									{
										key: "inches",
										value: "inches",
										text: intl.formatMessage({ id: "Inches" }),
									},
									{
										key: "mm",
										value: "mm",
										text: intl.formatMessage({ id: "Millimeters" }),
									},
								]}
								data-cy="settings-unit-of-measurement"
							/>
						</Grid.Column>
						<Grid.Column>
							<FormattedMessage id="Customer ID" />
							<Form.Input
								action={
									<Button
										icon={<Copy />}
										onClick={() => {
											navigator.clipboard.writeText(currentUser.CustomerId);
										}}
										data-cy="settings-customer-id-clipboard-button"
									/>
								}
								className={classes.readonly}
								placeholder={intl.formatMessage({ id: "Customer ID" })}
								readOnly
								value={currentUser.CustomerId ?? intl.formatMessage({ id: "Default" })}
								data-cy="settings-customer-id"
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={2}>
						<Grid.Column>
							<FormattedMessage id="User Logout Expiration" />
							<Form.Select
								placeholder={intl.formatMessage({ id: "User Logout Expiration" })}
								value={settings.loginExpirationInHours <= 12 ? 12 : 24}
								onChange={(e, { value }) => {
									setSettings({ ...settings, loginExpirationInHours: value });
									setIsDirty(true);
								}}
								options={[
									{
										key: "12",
										value: 12,
										text: intl.formatMessage({ id: "12 Hours" }),
									},
									{
										key: "24",
										value: 24,
										text: intl.formatMessage({ id: "24 Hours" }),
									},
								]}
								data-cy="settings-logout-expiration"
							/>
						</Grid.Column>
						<Grid.Column>
							<FormattedMessage id="Company" />
							<Form.Input
								action={
									<Button
										icon={<Copy />}
										onClick={() => {
											navigator.clipboard.writeText(currentUser.Company);
										}}
										data-cy="settings-company-clipboard-button"
									/>
								}
								className={classes.readonly}
								placeholder={intl.formatMessage({ id: "Company" })}
								readOnly
								value={
									currentUser.Company && currentUser.Company !== ""
										? currentUser.Company
										: intl.formatMessage({ id: "Default" })
								}
								data-cy="settings-company"
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={2}>
						<Grid.Column>
							<FormattedMessage id="Connection Status" />
							<div className={classes.licensingServer}>
								{showConnectionTest ? (
									<>
										<Icon name="check circle" color="green" size="large" />
										<FormattedMessage id="Licensing Server Connected" />
									</>
								) : (
									<>
										<Icon name="warning circle" color="red" size="large" />
										<FormattedMessage id="Licensing Server Disconnected" />
									</>
								)}

								{showConnectionTest && flagsAvailable ? (
									<>
										<div>
											<Icon name="check circle" color="green" size="large" />
											<FormattedMessage id="Launch Darkly Connection Success" />
										</div>
									</>
								) : (
									<>
										<div>
											<Icon name="warning circle" color="red" size="large" />
											<FormattedMessage id="Launch Darkly Connection Failed" />
										</div>
									</>
								)}
							</div>
						</Grid.Column>
						<Grid.Column>
							<FormattedMessage id="Site Time Zone" />
							<Form.Input
								icon="ban"
								className={classes.readonly}
								placeholder={intl.formatMessage({ id: "Site Time Zone" })}
								disabled
								value={siteTimeZone?.siteTimeZone}
								data-cy="settings-site-time-zone"
							/>
						</Grid.Column>
					</Grid.Row>
					{showFailStagingConfig && (
						<Grid.Row columns={2}>
							<Grid.Column />
							<Grid.Column>
								<Flex column gap={8}>
									<Checkbox
										label="Fail Jobs for Required Machine Group"
										checked={settings.failJobsForRequiredMachineGroup}
										onChange={(_, data) => {
											setIsDirty(true);
											setSettings((prevState) => ({
												...prevState,
												failJobsForRequiredMachineGroup: data.checked,
											}));
										}}
									/>
									<Checkbox
										label="Fail Jobs for Required Corrugate"
										checked={settings.failJobsForRequiredCorrugate}
										onChange={(_, data) => {
											setIsDirty(true);
											setSettings((prevState) => ({
												...prevState,
												failJobsForRequiredCorrugate: data.checked,
											}));
										}}
									/>
								</Flex>
							</Grid.Column>
						</Grid.Row>
					)}
				</Grid>
			</Form>
		</Segment>
	);
});

ServerSettingsTab.displayName = "ServerSettingsTab";

export default ServerSettingsTab;

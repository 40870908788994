import axios from "axios";
import { readLocalStorage } from "@/api/local-storage";
import { formatUri } from "./date-moment";

export function constructUrl(endpoint, dateFilter, usesPlannedProduction) {
	let url = `${endpoint}?startDate=${formatUri(dateFilter.start)}&endDate=${formatUri(dateFilter.end)}&offsetInMinutes=${dateFilter.offset}`;

	if (usesPlannedProduction) {
		url += `&timeZoneId=${dateFilter.timeZoneId}`;
	}

	return url;
}

const cancelTokenMap = new Map();

export function manageCancelToken(url) {
	if (cancelTokenMap.has(url)) {
		cancelTokenMap.get(url).cancel("Operation canceled due to new request.");
	}
	const newCancelToken = axios.CancelToken.source();
	cancelTokenMap.set(url, newCancelToken);

	return newCancelToken.token;
}

export async function fetchData(url, body, cancelToken) {
	const authToken = readLocalStorage("BEARER");
	const response = await axios.post(url, body, {
		headers: { Authorization: authToken },
		cancelToken: cancelToken,
	});
	return response.data;
}

import { Fragment } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { Grid } from "semantic-ui-react";
import { useIntl } from "react-intl";
import Cube from "@/views/dimensioning/capture-forms/3d-box";
import RadioButton from "@/components/radio-button";
import { FormattedMessage } from "react-intl";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	prompt: {
		paddingBottom: "8px",
	},
	message: {
		paddingTop: "32px",
		paddingBottom: "8px",
	},
	mainInput: {
		width: "400px !important",
	},
	dims: {
		textAlign: "center",
	},
	radioGroup: {
		display: "flex",
		alignItems: "flex-start",
		"& > *": {
			marginRight: "32px",
		},
	},
	radioBlock: {
		marginTop: "8px",
		paddingTop: "2px",
		display: "block !important",
		fontFamily: "Stolzl-Book !important",
	},
	selected: {
		backgroundColor: "#EBF7FF",
		border: "2px solid #0068AB",
		borderRadius: "8px",
		cursor: "pointer",
		margin: "16px 16px 0px 16px",
	},
	unselected: {
		backgroundColor: "#F2F4F5",
		cursor: "pointer",
		borderRadius: "8px",
		margin: "16px 16px 0px 16px",
	},
	productNote: {
		color: "#7C7B79",
		fontFamily: "Stolzl",
		fontSize: "14px",
		fontStyle: "normal",
		fontWeight: "400",
		lineHeight: "normal",
		margin: "0px 0px 0px 16px",
	},
}));

const DimOrientation = ({
	product,
	setProduct,
	cube1Selected,
	setCube1Selected,
	cube2Selected,
	setCube2Selected,
	cube3Selected,
	setCube3Selected,
}) => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });

	return (
		<Fragment>
			<div className={classes.prompt}>{intl.formatMessage({ id: "DimOrientationLockedPrompt" })}</div>
			<div className={classes.radioGroup}>
				<RadioButton
					name="orientationLocked"
					className={classes.radioBlock}
					value="yes"
					checked={product?.orientationLocked ?? false}
					onChange={() => setProduct({ ...product, orientationLocked: true })}
				>
					<FormattedMessage id="Yes" />
				</RadioButton>
				<RadioButton
					name="orientationLocked"
					className={classes.radioBlock}
					value="no"
					checked={!product?.orientationLocked}
					onChange={() => setProduct({ ...product, orientationLocked: false })}
				>
					<FormattedMessage id="No" />
				</RadioButton>
			</div>
			{product.orientationLocked && (
				<Fragment>
					<div className={classes.message}>{intl.formatMessage({ id: "DimOrientationLockedMessage" })}</div>
					<Grid>
						<Grid.Row>
							<div
								className={cube1Selected ? classes.selected : classes.unselected}
								onClick={() => {
									setCube1Selected(true);
									setCube2Selected(false);
									setCube3Selected(false);
								}}
							>
								<Cube
									dim1={product.dimensions.length}
									dim2={product.dimensions.width}
									dim3={product.dimensions.height}
								/>
								<p className={classes.dims}>
									{product.dimensions.length} X {product.dimensions.width} X {product.dimensions.height}
								</p>
							</div>

							<div
								className={cube2Selected ? classes.selected : classes.unselected}
								onClick={() => {
									setCube1Selected(false);
									setCube2Selected(true);
									setCube3Selected(false);
								}}
							>
								<Cube
									dim1={product.dimensions.width}
									dim2={product.dimensions.height}
									dim3={product.dimensions.length}
								/>
								<p className={classes.dims}>
									{product.dimensions.width} X {product.dimensions.height} X {product.dimensions.length}
								</p>
							</div>

							<div
								className={cube3Selected ? classes.selected : classes.unselected}
								onClick={() => {
									setCube1Selected(false);
									setCube2Selected(false);
									setCube3Selected(true);
								}}
							>
								<Cube
									dim1={product.dimensions.height}
									dim2={product.dimensions.length}
									dim3={product.dimensions.width}
								/>
								<p className={classes.dims}>
									{product.dimensions.height} X {product.dimensions.length} X {product.dimensions.width}
								</p>
							</div>
						</Grid.Row>
						<Grid.Row>
							<span className={classes.productNote}>*Product dimensions will be reordered based on your selection</span>
						</Grid.Row>
					</Grid>
				</Fragment>
			)}
		</Fragment>
	);
};

export default DimOrientation;

import { createContext, useContext, useEffect, useState } from "react";
import { PublicSettingsContext } from "@/contexts/public-settings-context.jsx";
import { initialize } from "launchdarkly-js-client-sdk";
import LoadingScreen from "@/views/login/loading-screen.jsx";

const featureFlagContext = createContext({});

// This context should eventually subscribe to flag updates live and show them real time instead of on load.
export const FeatureFlagProvider = ({ children }) => {
	const publicSettings = useContext(PublicSettingsContext);
	const [initialized, setInitialized] = useState(false);
	const [client, setClient] = useState(null);

	const setFeatureFlagIdentity = async (featureFlagIdentity) => {
		let newContext = {
			kind: "user",
			anonymous: true,
		};

		if (featureFlagIdentity?.id) {
			newContext = {
				kind: "user",
				anonymous: false,
				key: `user-${featureFlagIdentity.id}`,
				email: featureFlagIdentity?.email,
				userName: featureFlagIdentity?.userName,
			};
		}

		if (client) await client.identify(newContext);
	};

	const featureFlagEnabled = (featureFlag, defaultValue = false) => {
		if (!client?.variation) return defaultValue;
		return client.variation(featureFlag, defaultValue);
	};

	useEffect(() => {
		if (publicSettings?.launchDarklyKey === "") return;

		let newContext = {
			kind: "user",
			anonymous: true,
		};

		const LDClient = initialize(publicSettings.launchDarklyKey, newContext);
		setClient(LDClient);

		LDClient.waitForInitialization(3)
			.then(() => {
				setInitialized(true);
			})
			.catch((err) => {
				setClient(undefined);
				console.error(
					"PackNet user experience degraded due to failure to connect to LaunchDarkly. Ensure LaunchDarkly is on allow list.",
				);
				console.error(err);
				setInitialized(true);
			});
	}, [publicSettings]);

	return (
		<featureFlagContext.Provider value={{ featureFlagEnabled, setFeatureFlagIdentity, flagsAvailable: initialized }}>
			{initialized ? children : <LoadingScreen />}
		</featureFlagContext.Provider>
	);
};
export const useFeatureFlags = () => useContext(featureFlagContext);

import { baseRoutes } from "@/api/base-routes";
import { constructUrl, fetchData, manageCancelToken } from "../../../functions/api-request";

export async function getRawDataByPlannedProductionSchedulesForSite(
	schedules,
	sites,
	machinesSelected,
	dateFilter,
	useLargerUom,
	isSiteMetric,
) {
	if (!sites.length || !dateFilter?.id || !dateFilter.start) {
		return [];
	}

	const endpoint = `${baseRoutes.reporting}/v1/RawData/ByProductionSchedules`;
	let url = constructUrl(endpoint, dateFilter, false);

	if (useLargerUom) {
		url += `&useLargerUnitOfMeasure=${useLargerUom}&unitOfMeasure=${isSiteMetric ? "metric" : "imperial"}`;
	}

	const body = { schedules, sites, machines: machinesSelected };
	const cancelToken = manageCancelToken(endpoint);
	return fetchData(url, body, cancelToken);
}

export async function getRawData(sites, dateFilter) {
	if (!sites.length || !dateFilter?.id || !dateFilter.start) {
		return [];
	}

	const endpoint = `${baseRoutes.reporting}/v1/RawData`;
	const url = constructUrl(endpoint, dateFilter, false);
	const body = sites;
	const cancelToken = manageCancelToken(endpoint);

	return fetchData(url, body, cancelToken);
}

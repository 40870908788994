import { identityApi } from "@/api/identitySdk.js";

let cachedResponses = {};

export async function getCurrentTimeZoneForSite(tenantId) {
	if (cachedResponses[tenantId]) {
		return cachedResponses[tenantId];
	}

	try {
		const response = await identityApi.getTenantTimezone(tenantId);

		if (response.status === 200) {
			cachedResponses[tenantId] = response;
		}

		return response;
	} catch (error) {
		console.error("Error fetching timezone:", error);
		throw error;
	}
}

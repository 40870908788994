import { createContext, useContext, useState } from "react";
import { ProductionContext } from "@/contexts/production-context";
import Loading from "@/components/loading";
import FailedRetry from "@/components/failed-retry";
import AvailableJobsTable from "./available-jobs-table";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useAxios from "@/api/useAxios";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";
import Flex from "@/components/flex";
import styled from "styled-components";
import InProductionOrdersCard from "./in-production-orders-card/in-production-orders-card";
import Card from "@/components/card";
import { BodyRegular, SmallBook } from "@/components/typography";
import { baseRoutes } from "@/api/base-routes.js";
import useToken from "@/hooks/use-token";
import {
	isScanToTriggerConfigured,
	isScanToTriggerSingleConfigured,
} from "@/views/configuration/production-groups/manual-scan-to-produce-dropdown";
import { useCancellingQuery } from "@/api/useCancellingQuery";

export const OrdersContainer = styled(Flex)`
	padding: 24px;
	background-color: ${(props) => props.theme.colors.lightBackground};
	min-height: calc(100vh - ${(props) => props.theme.measurements.menuBarHeight});
`;

export const FlexCard = styled(Card)`
	&&& {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 0;
	}
`;

export const DarkGreyText = styled(SmallBook)`
	color: ${(props) => props.theme.colors.darkGreyText};
`;

export const OrdersContext = createContext({
	scanToTriggerConfigured: false,
	scanToTriggerSingleConfigured: false,
	triggerJobByLPN: () => {},
	triggerSingleJob: () => {},
	removeJob: () => {},
	produceNext: () => {},
	produceNow: () => {},
});

function Orders() {
	const intl = useIntl();
	const { currentProductionGroup, currentMachineGroup } = useContext(ProductionContext);
	const productionGroupId = currentProductionGroup?.id;

	const scanToTriggerConfigured = isScanToTriggerConfigured(currentProductionGroup.manualScanToProduce);
	const scanToTriggerSingleConfigured = isScanToTriggerSingleConfigured(currentProductionGroup.manualScanToProduce);

	const token = useToken();
	const queryClient = useQueryClient();

	const [pageNumber, setPageNumber] = useState(1);
	const pageSize = 10;

	const selectionApiRemoveBatch = useAxios(`${baseRoutes.selection}/v1/selection/packagingSolutions/byBatchId`, token);
	const selectionApi = useAxios(`${baseRoutes.selection}/v1`, token);

	const orderByQueryKey = ["selectionPipelineOrderBy", productionGroupId];

	const orderByQueryFn = async () => {
		const response = await selectionApi.getWithUrl(
			`SelectionPipelineConfigurations/${currentProductionGroup.pipelineId}/orderBy`,
		);
		return response.data.sortSteps;
	};

	const { data: orderByData, isLoading: orderByLoading } = useQuery({
		queryKey: orderByQueryKey,
		queryFn: orderByQueryFn,
		refetchInterval: false,
	});

	const batchSummaryQueryKey = ["selectionBatchSummary", productionGroupId, pageNumber];

	const batchSummaryQueryFn = async () => {
		let data;
		await selectionApi.addWithUrl(
			`batch/batchSummary/${productionGroupId}/pageNumber/${pageNumber}/pageSize/${pageSize}`,
			orderByData,
			(responseData) => (data = responseData),
		);

		return data;
	};

	const { data, error, isLoading, refetch } = useCancellingQuery({
		queryKey: batchSummaryQueryKey,
		queryFn: batchSummaryQueryFn,
		refetchIntervalMs: 3000,
		enabled: Boolean(productionGroupId) && Boolean(orderByData),
		placeholderData: keepPreviousData,
	});

	const triggerJobByLPNMutation = useMutation({
		mutationFn: ({ currentMachineGroup, lpn }) => {
			return selectionApi.updateWithUrl(
				`triggerProduction/packagingSolutions/forMachineGroup/${currentMachineGroup.id}/byLicensePlateNumber/${lpn}`,
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: batchSummaryQueryKey.slice(0, -1),
			});
			enqueueSnackbar(
				intl.formatMessage({ id: "Successfully Triggered Job", defaultMessage: "Successfullly Triggered Job" }),
				{
					variant: "customSuccess",
				},
			);
		},
		onError: (error) => {
			console.error("Failed to trigger job", error);
			enqueueSnackbar(intl.formatMessage({ id: "Failed To Trigger Job", defaultMessage: "Failed to Trigger Job" }), {
				variant: "customError",
			});
		},
	});

	const removeJobMutation = useMutation({
		mutationFn: (id) => {
			return selectionApiRemoveBatch.remove(id);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: batchSummaryQueryKey.slice(0, -1),
			});
			enqueueSnackbar(
				intl.formatMessage({ id: "Successfully Removed Job", defaultMessage: "Successfullly Removed Job" }),
				{
					variant: "customSuccess",
				},
			);
		},
		onError: (error) => {
			console.error("Failed to remove job", error);
			enqueueSnackbar(intl.formatMessage({ id: "Failed To Remove Job", defaultMessage: "Failed to Remove Job" }), {
				variant: "customError",
			});
		},
	});

	const produceNextMutation = useMutation({
		mutationFn: (batchId) => {
			return selectionApi.updateWithUrl(
				`batch/machineGroupId/${currentMachineGroup.id}/batchId/${batchId}/ProduceNext`,
			);
		},
		onSuccess: () => {
			enqueueSnackbar(intl.formatMessage({ id: "Producing Next", defaultMessage: "Producing Next" }), {
				variant: "customSuccess",
			});
		},
		onError: (error) => {
			console.error("Failed to produce next", error);
			const defaultMessage = "Failed to Produce Next";
			const errorMessage = error?.response?.data || defaultMessage;
			enqueueSnackbar(intl.formatMessage({ id: errorMessage, defaultMessage }), {
				variant: "customError",
			});
		},
	});

	const produceNowMutation = useMutation({
		mutationFn: (batchId) => {
			return selectionApi.addWithUrl(`batch/batchId/${batchId}/ProduceNow`, {
				machineGroupId: currentMachineGroup.id,
			});
		},
		onSuccess: () => {
			enqueueSnackbar(intl.formatMessage({ id: "Producing Now", defaultMessage: "Producing Now" }), {
				variant: "customSuccess",
			});
		},
		onError: (error) => {
			console.error("Failed to produce now", error);
			const defaultMessage = "Failed to Produce Now";
			const errorMessage = error?.response?.data || defaultMessage;
			enqueueSnackbar(intl.formatMessage({ id: errorMessage, defaultMessage }), {
				variant: "customError",
			});
		},
	});

	const triggerJobMutation = useMutation({
		mutationFn: ({ machineGroupId, batchId }) => {
			return axios.put(
				`/SelectionPipelineApi/api/v1/triggerProduction/packagingSolutions/forMachineGroup/${machineGroupId}/forBatch/${batchId}/single`,
				null,
				{
					headers: { Authorization: token },
				},
			);
		},
		onSuccess: (response) => {
			if (response.status === 200) {
				enqueueSnackbar(
					intl.formatMessage({ id: "Successfully Triggered Job", defaultMessage: "Successfully Triggered Job" }),
					{
						variant: "customSuccess",
					},
				);
			} else {
				enqueueSnackbar(
					intl.formatMessage({ id: "No Jobs Remaining to Trigger", defaultMessage: "No Jobs Remaining to Trigger" }),
					{
						variant: "customInfo",
					},
				);
			}
		},
		onError: (error) => {
			console.error("Failed to trigger job", error);
			enqueueSnackbar(intl.formatMessage({ id: "Failed To Trigger Job", defaultMessage: "Failed to Trigger Job" }), {
				variant: "customError",
			});
		},
	});

	if (isLoading || orderByLoading) {
		return <Loading />;
	}
	if (error || !data) {
		return <FailedRetry retry={refetch} />;
	}

	return (
		<OrdersContext.Provider
			value={{
				scanToTriggerConfigured,
				scanToTriggerSingleConfigured,
				triggerJobByLPN: (currentMachineGroup, lpn) => triggerJobByLPNMutation.mutate({ currentMachineGroup, lpn }),
				triggerSingleJob: (machineGroupId, batchId) => triggerJobMutation.mutate({ machineGroupId, batchId }),
				removeJob: (id) => removeJobMutation.mutate(id),
				produceNext: (batchId) => produceNextMutation.mutate(batchId),
				produceNow: (batchId) => produceNowMutation.mutate(batchId),
			}}
		>
			<OrdersContainer column gap={16}>
				<InProductionOrdersCard />
				<FlexCard>
					<Flex justifyBetween alignCenter style={{ padding: "16px" }}>
						<BodyRegular>
							<FormattedMessage
								id="Available Orders In {pgAlias}"
								defaultMessage="Available Orders In {pgAlias}"
								values={{ pgAlias: currentProductionGroup.alias }}
							/>
						</BodyRegular>
						<DarkGreyText>{`${data.totalResults} ${intl.formatMessage({ id: "Available" })}`}</DarkGreyText>
					</Flex>
					<AvailableJobsTable
						batches={data.results}
						setPageNumber={setPageNumber}
						pageSize={pageSize}
						isLoading={isLoading}
						totalResults={data.totalResults}
					/>
				</FlexCard>
			</OrdersContainer>
		</OrdersContext.Provider>
	);
}

export default Orders;

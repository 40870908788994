export const Warning = ({ size, color, dataTooltipId }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			data-tooltip-id={dataTooltipId}
			viewBox="0 0 36 36"
			fill="none"
		>
			<g clipPath="url(#clip0_3229_2020)">
				<path
					d="M4.08743 31.5C3.81243 31.5 3.56243 31.4313 3.33743 31.2938C3.11243 31.1563 2.93743 30.975 2.81243 30.75C2.68743 30.525 2.61868 30.2813 2.60618 30.0188C2.59368 29.7563 2.66243 29.5 2.81243 29.25L16.6874 5.25C16.8374 5 17.0312 4.8125 17.2687 4.6875C17.5062 4.5625 17.7499 4.5 17.9999 4.5C18.2499 4.5 18.4937 4.5625 18.7312 4.6875C18.9687 4.8125 19.1624 5 19.3124 5.25L33.1874 29.25C33.3374 29.5 33.4062 29.7563 33.3937 30.0188C33.3812 30.2813 33.3124 30.525 33.1874 30.75C33.0624 30.975 32.8874 31.1563 32.6624 31.2938C32.4374 31.4313 32.1874 31.5 31.9124 31.5H4.08743ZM6.67493 28.5H29.3249L17.9999 9L6.67493 28.5ZM17.9999 27C18.4249 27 18.7812 26.8563 19.0687 26.5688C19.3562 26.2812 19.4999 25.925 19.4999 25.5C19.4999 25.075 19.3562 24.7188 19.0687 24.4313C18.7812 24.1438 18.4249 24 17.9999 24C17.5749 24 17.2187 24.1438 16.9312 24.4313C16.6437 24.7188 16.4999 25.075 16.4999 25.5C16.4999 25.925 16.6437 26.2812 16.9312 26.5688C17.2187 26.8563 17.5749 27 17.9999 27ZM17.9999 22.5C18.4249 22.5 18.7812 22.3563 19.0687 22.0688C19.3562 21.7812 19.4999 21.425 19.4999 21V16.5C19.4999 16.075 19.3562 15.7188 19.0687 15.4312C18.7812 15.1438 18.4249 15 17.9999 15C17.5749 15 17.2187 15.1438 16.9312 15.4312C16.6437 15.7188 16.4999 16.075 16.4999 16.5V21C16.4999 21.425 16.6437 21.7812 16.9312 22.0688C17.2187 22.3563 17.5749 22.5 17.9999 22.5Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_3229_2020">
					<rect width="36" height="36" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Warning;

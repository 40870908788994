import { Button, Modal } from "semantic-ui-react";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "@/components/user-context";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import { useAuthentication } from "@/contexts/authentication-context.jsx";
import Warning from "@/components/warning.jsx";

const useStyles = createUseStyles(() => ({
	modal: {
		padding: "0 !important",
	},
	iconBackground: {
		background: "rgba(255, 192, 0, .1)",
		width: "60px",
		height: "60px",
		borderRadius: "10px",
		alignItems: "center",
		display: "flex",
		alignContent: "center",
		justifyContent: "center",
	},
	iconContainer: {
		display: "flex",
		alignContent: "center",
		justifyContent: "center",
		minHeight: "60px",
	},
	title: {
		fontSize: "32pt !important",
		fontFamily: "Stolzl-Medium",
		fontWeight: 500,
		borderBottom: "none !important",
		display: "flex !important",
		flexDirection: "column !important",
		gap: "40px",
		alignItems: "center",
	},
	titleText: {
		paddingLeft: "12px",
	},
	content: {},
	saveButton: {
		marginRight: "24px !important",
	},
	detail: {
		fontSize: "16pt !important",
		alignItems: "left",
	},
	buttons: {
		border: "none !important",
		background: "var(--Main-Light---Cloud, #F2F4F5);",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",

		"& .ui.basic.primary.button": {
			textTransform: "none",
			"&:hover": {
				boxShadow: "none !important",
			},
			boxShadow: "none !important",
		},
	},
	checkboxContainer: {
		flex: 1,
		"& i": {
			marginRight: "4px !important",
		},
	},
}));

export default function LogoutWarningModal() {
	const classes = useStyles();
	const [time, setTime] = useState(0);
	const [open, setOpen] = useState(false);
	const { logout } = useContext(UserContext);
	const { refreshToken, token } = useAuthentication();
	const timerRef = useRef(null);

	useEffect(() => {
		const secondsSinceEpoch = new Date() / 1000;

		// no token? No dice
		if (!token) return;
		// Token already expired? Get out
		if (token?.exp - secondsSinceEpoch <= 0) logout("Expired token", "/logout");

		// clear interval if it exists after the token changes
		if (timerRef.current) clearInterval(timerRef.current);

		timerRef.current = setInterval(() => {
			const secondsSinceEpoch = new Date() / 1000; // seconds since epoch
			const localExpire = token?.exp; //seconds since epoch

			// is the token expired?
			let expiresInSeconds = localExpire - secondsSinceEpoch;

			// if it's 2 minutes to expiry or less, show the modal
			if (expiresInSeconds <= 120) {
				setOpen(true);
			}

			// if it's already expired? Logout
			if (expiresInSeconds <= 0) {
				setOpen(false);
				logout(`expiresInSeconds was empty or refresh timer expired ${expiresInSeconds}`, "/logged-out");
			}

			// show the current time
			setTime(expiresInSeconds);
		}, 1000);

		return () => {
			// clear if not already cleared? Idk about this one
			if (timerRef.current) clearInterval(timerRef.current);
		};
	}, [token]);

	const countdown = !time
		? ""
		: `${Math.floor(time / 60)}:${Math.round(time - Math.floor(time / 60) * 60)
				.toString()
				.padStart(2, "0")}`;

	const closeModal = () => {
		setOpen(false);
		clearInterval(timerRef.current);
		timerRef.current = null;
		setTime(0);
	};
	const endSession = () => {
		closeModal();
		logout("User Clicked Logout", "/login");
	};

	async function continueSession() {
		closeModal();
		refreshToken();
	}

	return (
		<Modal
			size="small"
			centered={true}
			closeOnEscape={false}
			closeOnDimmerClick={false}
			className={classes.modal}
			open={open}
		>
			<Modal.Content className={classes.title}>
				<div className={classes.iconContainer}>
					<div className={classes.iconBackground}>
						<Warning color={"#FFC000"} size={"48px"} />
					</div>
				</div>
				<div className={classes.titleText}>
					<FormattedMessage id="Session Will Expire in" values={{ countdown }} />
				</div>
			</Modal.Content>
			<Modal.Actions className={classes.buttons}>
				<Button primary basic onClick={() => endSession()}>
					<FormattedMessage id="Log Out Now" />
				</Button>
				<Button primary onClick={() => continueSession()}>
					<FormattedMessage id="ContinueSession" />
				</Button>
			</Modal.Actions>
		</Modal>
	);
}

import { baseRoutes } from "@/api/base-routes";
import { constructUrl as constructUrlDateFilter, fetchData, manageCancelToken } from "../../../functions/api-request";

export async function getTrimByPlannedProductionSchedulesForSite(schedules, sites, dateFilter) {
	if (!sites.length || !dateFilter?.id || !dateFilter.start) {
		return [];
	}

	const endpoint = `${baseRoutes.reporting}/v1/Trim/ByProductionSchedules`;
	const url = constructUrlDateFilter(endpoint, dateFilter, false);
	const body = { schedules, sites };
	const cancelToken = manageCancelToken(endpoint);

	return fetchData(url, body, cancelToken);
}
export async function getTrim(sites, dateFilter) {
	if (!sites.length || !dateFilter?.id || !dateFilter.start) {
		return [];
	}

	const endpoint = `${baseRoutes.reporting}/v1/Trim`;
	const url = constructUrlDateFilter(endpoint, dateFilter, false);
	const body = sites;
	const cancelToken = manageCancelToken(endpoint);

	return fetchData(url, body, cancelToken);
}

export async function getBucketTrimByPlannedProductionSchedulesForSite(schedules, sites, machinesSelected, dateFilter) {
	if (!sites.length || !dateFilter?.id || !dateFilter.start) {
		return [];
	}

	const endpoint = `${baseRoutes.reporting}/v1/Trim/TrimBuckets`;
	const url = constructUrlDateFilter(endpoint, dateFilter, false);
	const body = { schedules, sites, machines: machinesSelected };
	const cancelToken = manageCancelToken(endpoint);

	return fetchData(url, body, cancelToken);
}

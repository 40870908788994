import { baseRoutes } from "../../../../../api/base-routes";
import { constructUrl, fetchData, manageCancelToken } from "../../../functions/api-request";

export async function getBucketZFoldUsageByPlannedProductionSchedulesForSite(
	schedules,
	sites,
	machinesSelected,
	dateFilter,
) {
	if (!sites.length || !dateFilter?.id || !dateFilter.start) {
		return [];
	}

	const endpoint = `${baseRoutes.reporting}/v1/ZFoldUsage/ZFoldUsageBuckets`;

	const url = constructUrl(endpoint, dateFilter, false);
	const body = { schedules, sites, machines: machinesSelected };
	const cancelToken = manageCancelToken(endpoint);
	return fetchData(url, body, cancelToken);
}

import { Dropdown, Icon } from "semantic-ui-react";
import Flex from "@/components/flex";
import { HighlightOff, MoveUp, Upgrade } from "@styled-icons/material-rounded";
import TwoLineDropdownItem from "@/components/dropdown/two-line-dropdown-item";
import { useContext } from "react";
import { ProductionContext } from "@/contexts/production-context";
import { OrdersContext } from "@/views/production/orders/orders";
import { useCanManageJobs } from "@/components/user-context";

const AvailableJobsActionMenu = ({ batchId }) => {
	const canManageJobs = useCanManageJobs();
	const { currentMachineGroup } = useContext(ProductionContext);
	const { scanToTriggerSingleConfigured, removeJob, produceNext, produceNow, triggerSingleJob } =
		useContext(OrdersContext);

	return (
		<Dropdown icon={<Icon name="ellipsis vertical" />} direction="left">
			<Dropdown.Menu>
				{canManageJobs && (
					<Dropdown.Item onClick={() => removeJob(batchId)}>
						<Flex alignCenter gap={12}>
							<HighlightOff size={24} />
							<TwoLineDropdownItem mainTextId="Cancel Production" subTextId="Do not produce" />
						</Flex>
					</Dropdown.Item>
				)}
				{scanToTriggerSingleConfigured && (
					<Dropdown.Item onClick={() => triggerSingleJob(currentMachineGroup.id, batchId)}>
						<Flex alignCenter gap={12}>
							<MoveUp size={24} />
							<TwoLineDropdownItem mainTextId="Trigger" subTextId="Produce one job in batch" />
						</Flex>
					</Dropdown.Item>
				)}
				{!scanToTriggerSingleConfigured && (
					<Dropdown.Item onClick={() => produceNext(batchId)}>
						<Flex alignCenter gap={12}>
							<MoveUp size={24} />
							<TwoLineDropdownItem mainTextId="Produce Next" subTextId="Produce after current job" />
						</Flex>
					</Dropdown.Item>
				)}
				<Dropdown.Item onClick={() => produceNow(batchId)}>
					<Flex alignCenter gap={12}>
						<Upgrade size={24} />
						<TwoLineDropdownItem mainTextId="Produce Now" subTextId="Pause current job to produce" />
					</Flex>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default AvailableJobsActionMenu;

import { useEffect, useMemo, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Popup } from "semantic-ui-react";
import ByMachine from "./cards/by-machine";
import TotalUsage from "./cards/total-usage";
import DashboardCard from "@/views/reporting/dashboard-card";
import PercentByZFold from "./cards/percent-by-zfold";
import TotalUsageByZFold from "./cards/total-usage-by-z-fold";
import ZFoldOverTime from "./cards/z-fold-over-time";
import { getBucketZFoldUsageByPlannedProductionSchedulesForSite } from "@/views/reporting/services/API/Reports/z-fold-usage-api";
import { ReactComponent as ChartViewIcon } from "@/views/reporting/img/chartview-icon.svg";
import { ReactComponent as TableViewIcon } from "@/views/reporting/img/tableview-icon.svg";
import ExperienceContainer from "../layout/experience-container";
import Row from "../layout/row";
import { convertUomRawValue, getOriginalUOM, getTargetUOM } from "../functions/unit-of-measure";
import errorBoundary from "@/components/error-boundary";
import { isFeatureFlagEnabled } from "@/utils/auth";

const useStyles = createUseStyles(() => ({
	cardHeader: {
		display: "flex",
		justifyContent: "space-between",
		fontFamily: "Stolzl-Medium",
		fontSize: "24px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		paddingTop: "20px",
		paddingLeft: "0",
		paddingBottom: "31px",
		paddingRight: "0",
	},
	toggleBtnGrp: {
		"& > button:first-child": {
			borderTopRightRadius: "0px",
			borderBottomRightRadius: "0px",
		},
		"& > button:last-child": {
			borderTopLeftRadius: "0px",
			borderBottomLeftRadius: "0px",
		},
		"& > button": {
			height: "32px !important",
			width: "56px !important",
			padding: "0px !important",
			margin: "0px !important",
			border: "1px solid #dededf !important",
			"& svg": {
				height: "20px !important",
				width: "20px !important",
				alignItems: "center !important",
			},
		},
	},
	notActiveToggleBtn: {
		backgroundColor: "#ffffff !important",
		"& svg": {
			"& path": {
				fill: "#6b6b6b !important",
			},
		},
	},
	activeToggleBtn: {
		backgroundColor: "#f2f2f2 !important",
		"& svg": {
			"& path": {
				fill: "#353430 !important",
			},
		},
	},
}));

function ZFoldUsage({ currentUser }) {
	const intl = useIntl();
	const maxItemsOnCard = 6;
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const selectedSites = useMemo(() => [currentUser.CustomerId, currentUser.tid], [currentUser]);
	const [isMetric, setIsMetric] = useState(false);
	const [unitLabel, setUnitLabel] = useState("Square Feet");
	const isSiteMetric = currentUser.unitofmeasure === "mm";
	const [internalNavigation, setInternalNavigation] = useState("");
	const defaultColor = "#323430";
	const [showGraph, setShowGraph] = useState(true);
	const [showBales, setShowBales] = useState(false);
	const [footerText, setFooterText] = useState("");

	const [dateOptions, setDateOptions] = useState({});

	const [machines, setMachines] = useState(() => {
		const machinesSaved = localStorage.getItem("MACHINES");
		return machinesSaved !== null && machinesSaved !== "undefined" ? JSON.parse(machinesSaved) : [];
	});

	async function getData(sites) {
		setIsLoading(true);
		let plannedProduction = [];
		const featureFlagBale = isFeatureFlagEnabled(currentUser, "pack-net-reporting-bale");

		const zFoldUsage = await getBucketZFoldUsageByPlannedProductionSchedulesForSite(
			plannedProduction,
			sites,
			machines.filter((m) => m.checked).map((m) => m.id),
			dateOptions,
		);

		const [known, originalUOM] = getOriginalUOM(zFoldUsage, isSiteMetric);
		const preferredMeasurement = getTargetUOM(known, originalUOM, isSiteMetric);
		setIsMetric(preferredMeasurement === "squaredMeters");
		if (featureFlagBale) {
			setUnitLabel(
				preferredMeasurement === "squaredMeters" ? "Square Meters" + ` | ` + "Bales" : "Square Feet" + ` | ` + "Bales",
			);
			setFooterText("Bale calculations are estimated");
		} else {
			setUnitLabel(preferredMeasurement === "squaredMeters" ? "Square Meters" : "Square Feet");
			setFooterText("");
		}

		zFoldUsage.totalConsumedCorrugate = convertUomRawValue(
			zFoldUsage.totalConsumedCorrugate,
			originalUOM,
			preferredMeasurement,
		);
		zFoldUsage.byMachine = zFoldUsage.byMachine.map((item) => {
			return {
				...item,
				totalConsumedCorrugate: convertUomRawValue(item.totalConsumedCorrugate, originalUOM, preferredMeasurement),
			};
		});
		zFoldUsage.byZFold = zFoldUsage.byZFold.map((item) => {
			return {
				...item,
				totalConsumedCorrugate: convertUomRawValue(item.totalConsumedCorrugate, originalUOM, preferredMeasurement),
			};
		});
		zFoldUsage.overTime = zFoldUsage.overTime.map((item) => {
			item.data = item.data.map((dataItem) => {
				let totalConsumedCorrugate = convertUomRawValue(
					dataItem.totalConsumedCorrugate,
					originalUOM,
					preferredMeasurement,
				);
				return {
					...dataItem,
					totalConsumedCorrugate: totalConsumedCorrugate,
					value: totalConsumedCorrugate,
				};
			});
			return item;
		});

		setData(zFoldUsage);
		setIsLoading(false);
		setShowBales(featureFlagBale);
	}

	function showOverview() {
		const title = "zFold Usage Over Time";
		return [
			<Row size="half" key="row-1-left">
				<DashboardCard
					key="Total Usage"
					title="Total Usage"
					dateRange={dateOptions}
					subtitles={["Site", unitLabel]}
					footerText={footerText}
				>
					<TotalUsage reportData={data} color={defaultColor} isMetric={isMetric} showBales={showBales} />
				</DashboardCard>
			</Row>,
			<Row size="half" key="row-1-right">
				<DashboardCard
					key="Total Usage by z-Fold"
					title="Total Usage by zFold"
					dateRange={dateOptions}
					setInternalNavigation={setInternalNavigation}
					limit={maxItemsOnCard}
					navigationDestination={"totalUsageByZFold"}
					color={defaultColor}
					subtitles={["zFold Width", unitLabel]}
					footerText={footerText}
				>
					<TotalUsageByZFold
						reportData={data}
						dateRange={dateOptions}
						limit={maxItemsOnCard}
						color={defaultColor}
						isMetric={isMetric}
						showBales={showBales}
					/>
				</DashboardCard>
			</Row>,
			<Row size="full" key="row-2">
				<DashboardCard
					title={
						<div className={`${classes.cardHeader}`}>
							<FormattedMessage id={title} />
							<span className={classes.toggleBtnGrp}>
								<Popup
									inverted
									trigger={
										<Button
											className={showGraph ? classes.activeToggleBtn : classes.notActiveToggleBtn}
											onClick={() => setShowGraph(true)}
										>
											<ChartViewIcon />
										</Button>
									}
									content="Graph View"
									position="bottom center"
								/>
								<Popup
									inverted
									trigger={
										<Button
											className={!showGraph ? classes.activeToggleBtn : classes.notActiveToggleBtn}
											onClick={() => setShowGraph(false)}
										>
											<TableViewIcon />
										</Button>
									}
									content="Table View"
									position="bottom center"
								/>
							</span>
						</div>
					}
					dateRange={dateOptions}
				>
					<ZFoldOverTime
						key="z-Fold Usage Over Time"
						title="zFold Usage Over Time"
						reportData={data}
						dateRange={dateOptions}
						showGraph={showGraph}
						isMetric={isMetric}
					></ZFoldOverTime>
				</DashboardCard>
			</Row>,
			<Row size="half" bottom key="row-3-left">
				<DashboardCard
					key="Percentage Usage by z-Fold"
					title="Percentage Usage by zFold"
					dateRange={dateOptions}
					setInternalNavigation={setInternalNavigation}
					limit={maxItemsOnCard}
					navigationDestination={"percentageUsageByWidth"}
					color={defaultColor}
					subtitles={["zFold Width", "percent Used"]}
				>
					<PercentByZFold
						reportData={data}
						dateRange={dateOptions}
						limit={maxItemsOnCard}
						color={defaultColor}
						isMetric={isMetric}
					/>
				</DashboardCard>
			</Row>,
			<Row size="half" bottom key="row-3-right">
				<DashboardCard
					key="Usage by Machine"
					title="Usage by Machine"
					dateRange={dateOptions}
					setInternalNavigation={setInternalNavigation}
					limit={maxItemsOnCard}
					navigationDestination={"usageByMachine"}
					color={defaultColor}
					subtitles={[
						"zFold Width",
						unitLabel.includes("|") ? unitLabel.substring(0, unitLabel.indexOf("|")) : unitLabel,
					]}
				>
					<ByMachine
						reportData={data}
						dateRange={dateOptions}
						limit={maxItemsOnCard}
						color={defaultColor}
						isMetric={isMetric}
					/>
				</DashboardCard>
			</Row>,
		];
	}

	function showTotalUsageByZFold() {
		return (
			<Row size="full">
				<DashboardCard
					key="Total Usage by z-Fold"
					title="Total Usage by zFold"
					dateRange={dateOptions}
					isFullScreen={true}
					subtitles={["zFold Width", unitLabel]}
					footerText={footerText}
				>
					<TotalUsageByZFold
						reportData={data}
						dateRange={dateOptions}
						setInternalNavigation={setInternalNavigation}
						limit={1000}
						color={defaultColor}
						isMetric={isMetric}
						showBales={showBales}
					/>
				</DashboardCard>
			</Row>
		);
	}

	function showMachine() {
		return (
			<Row size="full">
				<DashboardCard
					key="Usage by Machine"
					title="Usage by Machine"
					dateRange={dateOptions}
					isFullScreen={true}
					subtitles={[
						"zFold Width",
						unitLabel.includes("|") ? unitLabel.substring(0, unitLabel.indexOf("|")) : unitLabel,
					]}
				>
					<ByMachine
						reportData={data}
						dateRange={dateOptions}
						setInternalNavigation={setInternalNavigation}
						limit={1000}
						color={defaultColor}
						isMetric={isMetric}
					/>
				</DashboardCard>
			</Row>
		);
	}

	function showPercentageUsageByWidth() {
		return (
			<Row size="full">
				<DashboardCard
					key="Percentage Usage by z-Fold"
					title="Percentage Usage by zFold"
					dateRange={dateOptions}
					isFullScreen={true}
					subtitles={["zFold Width", "percent Used"]}
				>
					<PercentByZFold
						reportData={data}
						dateRange={dateOptions}
						setInternalNavigation={setInternalNavigation}
						limit={1000}
						color={defaultColor}
						isMetric={isMetric}
					/>
				</DashboardCard>
			</Row>
		);
	}

	function showDashboard() {
		switch (internalNavigation) {
			case "":
				return showOverview();
			case "totalUsageByZFold":
				return showTotalUsageByZFold();
			case "usageByMachine":
				return showMachine();
			case "percentageUsageByWidth":
				return showPercentageUsageByWidth();
			default:
				return <div>{intl.formatMessage({ id: "This is not the card you are looking for" })}</div>;
		}
	}
	useEffect(() => {
		if (dateOptions?.id) getData(selectedSites);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateOptions, selectedSites, machines]);

	return (
		<ExperienceContainer
			currentUser={currentUser}
			header="zFold Usage Overview"
			backMessage="Back to zFold Usage Overview"
			dateOptions={dateOptions}
			setDateOptions={setDateOptions}
			internalNavigation={internalNavigation}
			setInternalNavigation={setInternalNavigation}
			machines={machines}
			setMachines={setMachines}
			isLoading={isLoading}
			noData={(data.byMachine && data.byMachine.length === 0) || machines.every((m) => !m.checked)}
		>
			{showDashboard()}
		</ExperienceContainer>
	);
}
export default errorBoundary(ZFoldUsage);

import { baseRoutes } from "@/api/base-routes";
import { constructUrl, fetchData, manageCancelToken } from "../../../functions/api-request";

export async function getBucketOEEByPlannedProductionSchedulesForSite(
	schedules,
	sites,
	machinesSelected,
	dateFilter,
	usesPlannedProduction,
) {
	if (!sites.length || !dateFilter?.id || !dateFilter.start) {
		return {};
	}

	const endpoint = `${baseRoutes.reporting}/v1/OverallEquipmentEffectiveness`;
	const url = constructUrl(endpoint, dateFilter, usesPlannedProduction);
	const cancelToken = manageCancelToken(endpoint);

	const body = { schedules, sites, machines: machinesSelected };

	return fetchData(url, body, cancelToken);
}

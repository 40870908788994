import { useEffect, useState } from "react";

import ProgressBarList from "../../progress-bar-list";
import NoData from "@/views/reporting/no-data";
import { getPaginationResult } from "@/views/reporting/functions/swimlanes";
import SwimLanePagination from "@/views/reporting/swim-lane-pagination";
import { getAvailabilityDisplayData } from "@/views/reporting/functions/availability-data";
import { differenceMilliseconds } from "../../functions/date-moment";

function AvailabilityByMachine({ reportData, dateRange, limit, color, isNewFormat, sortByAmount, sortByMachine }) {
	const [presentationData, setPresentationData] = useState([]);
	const [pagination, setPagination] = useState({
		perPage: 6,
		page: 1,
	});

	function sortData(data) {
		let newData = [];
		if (sortByAmount === "desc") {
			newData = data.sort((a, b) => {
				return a.availability > b.availability ? -1 : 1;
			});
		} else if (sortByAmount === "asc") {
			newData = data.sort((a, b) => {
				return a.availability > b.availability ? 1 : -1;
			});
		}

		if (sortByMachine === "desc") {
			newData = data.sort((a, b) => {
				return b.machineName.localeCompare(a.machineName, undefined, { numeric: true });
			});
		} else if (sortByMachine === "asc") {
			newData = data.sort((a, b) => {
				return a.machineName.localeCompare(b.machineName, undefined, { numeric: true });
			});
		}
		return newData;
	}

	function mapData(data, dateRange) {
		let aggregation = [];
		if (isNewFormat) {
			aggregation = data.byMachine.map((item) => {
				item.percent = item.availability * 100;
				item.text = item.machineName;
				item.value = `${item.percent.toFixed(0)}%`;
				return item;
			});
			aggregation.sort((a, b) => (a.availability > b.availability ? -1 : 1));
		} else {
			const totalTime = differenceMilliseconds(dateRange);

			aggregation = data.reduce((groups, item) => {
				let group = groups.find((i) => i.machineId === item.machineId);
				let diff = 0;
				if (getAvailabilityDisplayData(item.status)[3] === true) {
					diff = Date.parse(item.endTime) - Date.parse(item.startTime);
				}
				if (!group) {
					return [
						...groups,
						{
							machineId: item.machineId,
							machineName: item.machineName,
							status: item.status,
							onlineTime: diff,
						},
					];
				}
				group.onlineTime += diff;
				return groups;
			}, []);

			aggregation.sort((a, b) => (a.onlineTime > b.onlineTime ? -1 : a.machineName > b.machineName ? 1 : -1));
			aggregation = aggregation.map((item) => {
				item.percent = (item.onlineTime / totalTime) * 100;
				item.text = item.machineName;
				item.value = `${item.percent.toFixed(0)}%`;
				return item;
			});
		}
		setPresentationData(sortData(aggregation));
	}

	useEffect(() => {
		if (reportData?.length || isNewFormat) mapData(reportData, dateRange);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportData, dateRange]);

	useEffect(() => {
		if (presentationData.length === 0) return;
		let newData = sortData(presentationData);
		if (newData.length > 0) {
			setPresentationData([...newData]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortByMachine, sortByAmount]);

	const paginationResult = getPaginationResult({ items: presentationData }, pagination.perPage, pagination.page);
	return (
		<>
			{isNewFormat ? reportData.byMachine.length === 0 && <NoData /> : reportData.length === 0 && <NoData />}
			{limit === 1000 && (isNewFormat ? reportData.byMachine.length : reportData.length) > 0 && (
				<ProgressBarList
					color={color}
					items={presentationData}
					limit={1000}
					key={`availability-by-machine-progress-bar-list-all`}
				/>
			)}
			{limit < 1000 && (isNewFormat ? reportData.byMachine.length : reportData.length) > 0 && (
				<ProgressBarList color={color} items={paginationResult.items} limit={paginationResult.items.length} />
			)}
			<SwimLanePagination
				pagination={pagination}
				setPagination={setPagination}
				paginationResult={paginationResult}
				limit={limit}
				key={`availability-by-machine-swim-lane-pagination`}
			/>
		</>
	);
}

export default AvailabilityByMachine;
